import React, { useState, createContext, useContext, useEffect } from 'react';
import { EditProductContext } from './EditProductContext';
import * as _ from 'lodash';
import { AddUserImage, RemoveUserImage } from '../components/Customize/Customize';
import { addDoc, listenDoc, listenRTDB, pushRTDB, upload, useFirestoreDocument, useFirestoreStorage } from '../firebase/firebase';
import { v4 as uuid } from 'uuid';
import { ContactSupportOutlined } from '@material-ui/icons';



export const UserSelectionContext = createContext<{
    quantity: number;
    setQuantity: Function;
    material: string;
    setMaterial: Function;
    generalOption: string;
    setGeneralOption: Function;
    modelName: string;
    setModelName: Function;
    variant: any;
    totalAmount: number;
    basePrice: number;
    selectedSection: Section;
    setSelectedSection: Function;
    sections: Section[];
    isSuccessDialogOpen: boolean;
    setIsSuccessDialogOpen: Function;
    isOutOfStockDialogOpen: boolean;
    setIsOutOfStockDialogOpen: Function;

    imageIds: string[];
    addUserImage: Function;
    removeUserImage: Function;
    userImageUrls: string[];

    hasFetchedAllData: boolean;
    hasAddedToShopifyCart: boolean;
    fsId: string;
    outOfStock: boolean;

    switchFsId: Function
}>(null);

export enum Section {
    MODEL,
    TEXT,
    GENERAL,
    IMAGES
}


const UserSelectionContextProvider = ({children}) => {
    const [quantity, setQuantity] = useState(1);
    const [material, setMaterial] = useState<string>(null);
    const [generalOption, setGeneralOption] = useState<string>(null);
    const [modelName, setModelName] = useState<string>(null);
    const [variant, setVariant] = useState<any>(null);
    const { 
        product,
        personalisationPrices,
        selectedPersonalisationPriceId,
        updateUserImageBoundary,
        models,
        selectedModelId,
        selectedBgIndex,
        firestoreData,
        getImageBoundaryId,
        selectModel
    } = useContext(EditProductContext);
    const [totalAmount, setTotalAmount] = useState(0);
    const [basePrice, setBasePrice] = useState(0);
    const [selectedSection, setSelectedSection] = useState(Section.MODEL);
    const sections = [Section.MODEL, Section.TEXT, Section.IMAGES, Section.GENERAL];
    const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
    const [isOutOfStockDialogOpen, setIsOutOfStockDialogOpen] = useState(false);
    const [switcherFsId, setSwitcherFsId] = useState(false)
   
    // const [imageIds, setImageIds] = useState([]);
    // const [userImageUrls, setUserImageUrls] = useState([]);

    const [bigImageIds, setBigImageIds] = useState<{[modelId: string]: string[]}>({});
    const [bigUserImageUrls, setBigUserImageUrls] = useState<{[modelId: string]: string[]}>({});

    const materialOptions = _.find(_.get(product, 'options') || [], (d) => d.name === 'Matériel') || {};
    const selectedMaterialName = _.get(materialOptions, ['values', selectedBgIndex], '');
    const generalOptions = _.find(_.get(product, 'options') || [], (d) => d.name !== 'Matériel' && d.name !== 'Modèle');

    const [hasFetchedAllData, setHasFetchedAllData] = useState(false);
    const [outOfStock, setOutOfStock] = useState(false);

    const [fsId, setFsId] = useState<string>(null);
    // const { data: addToCartCheckerData } = useFirestoreDocument(`userCustomizeAddToCart/${fsId}`);
    const [hasAddedToShopifyCart, setHasAddedToShopifyCart] = useState(false);
    const [addToCartCheckerData, setAddToCartCheckerData] = useState<any>({});
    
    const switchFsId = () => {
        setSwitcherFsId(!switcherFsId)
    }

    const addUserImage = async (file) => {
        try {
            const boundaryId = getImageBoundaryId(selectedModelId);
            const id = `model-${selectedModelId}-user-image-${uuid()}`;
            const oldSelectedModelId = selectedModelId;
            const url = await upload(file);
                selectModel(selectedModelId, false);
            // setUserImageUrls(current => [url, ...userImageUrls]);
            // setImageIds(current => [id, ...imageIds])

            if (!bigUserImageUrls[selectedModelId]) {
                bigUserImageUrls[selectedModelId] = [url];
            } else {
                bigUserImageUrls[selectedModelId] = [url, ...bigUserImageUrls[selectedModelId]];
            }
            if (!bigImageIds[selectedModelId]) {
                bigImageIds[selectedModelId] = [id];
            } else {
                bigImageIds[selectedModelId] = [id, ...bigImageIds[selectedModelId]];
            }
            setBigUserImageUrls({ ...bigUserImageUrls });
            setBigImageIds({ ...bigImageIds });

            AddUserImage(url, id, getImageBoundaryId(selectedModelId), updateUserImageBoundary);
        } catch(e) {
        }
    }

    const imageIds = _.get(bigImageIds, selectedModelId, []);
    const userImageUrls = _.get(bigUserImageUrls, selectedModelId, []);

    const removeUserImage = (index: number) => {
        // const id = imageIds[index];
        // userImageUrls.splice(index, 1);
        // imageIds.splice(index, 1);
        // setUserImageUrls([...userImageUrls]);
        // setImageIds([...imageIds]);
        const id = bigImageIds[selectedModelId][index];
        bigImageIds[selectedModelId].splice(index, 1);
        bigUserImageUrls[selectedModelId].splice(index, 1);
        setBigImageIds({ ...bigImageIds });
        setBigUserImageUrls({ ...bigUserImageUrls });
        RemoveUserImage(id);
    }

    
    const variants = _.get(product, 'variants', []);

    useEffect(() => {
        const selectedVariant = _.find(variants, d => {
            const splitted: string[] = d.title.split(' / ');
            return splitted.includes(material) && splitted.includes(generalOption);
        });

        setVariant(selectedVariant || null);

        if (selectedVariant) {
            setBasePrice(Number(selectedVariant['price']));
            const personalisationPrice = _.get(_.find(personalisationPrices, d => d.variantId == selectedPersonalisationPriceId), 'price', 0);
            setTotalAmount((quantity * Number(selectedVariant['price']) + Number(personalisationPrice)));
        }
    }, [quantity, material, modelName, generalOption, variants, selectedPersonalisationPriceId]);

    useEffect(() => {
        models[selectedModelId] && setModelName(models[selectedModelId]['modelName']);
    }, [selectedModelId, product, models]);

    useEffect(() => {
        selectedMaterialName && setMaterial(selectedMaterialName);
    }, [selectedMaterialName, product]);

    useEffect(() => {
        generalOptions && generalOptions.values && generalOptions.values[0] && setGeneralOption(generalOptions.values[0]);
    }, [generalOptions, product]);
    
    useEffect(() => {
        const materialOptions = _.find(_.get(product, 'options') || [], (d) => d.name === 'Matériel') || {};
        const selectedMaterialName = _.get(materialOptions, ['values', selectedBgIndex], '');
        const generalOptions = _.find(_.get(product, 'options') || [], (d) => d.name !== 'Matériel' && d.name !== 'Modèle');

        generalOptions && generalOptions.values && generalOptions.values[0] && setGeneralOption(generalOptions.values[0]);
        selectedMaterialName && setMaterial(selectedMaterialName);
        models[selectedModelId] && setModelName(models[selectedModelId]['modelName']);

        if (product && !_.isEmpty(firestoreData)) {
            setHasFetchedAllData(true);
        }
    }, [product, firestoreData]);

    useEffect(() => {
        let sub;
        // addDoc('/addedToCart', { added: false })
        //     .then((ref) => {
        //         setFsId(ref.id);
                
        //         sub = listenDoc(`/addedToCart/${ref.id}`).subscribe(res => {
        //             setHasAddedToShopifyCart(_.get(res, 'added', false));
        //             setOutOfStock(_.get(res, 'outOfStock', false));
        //         })
        //     });

        pushRTDB('/addedToCart', { added: false })
            .then((ref) => {
                setFsId(ref.key);
                
                sub = listenRTDB(`/addedToCart/${ref.key}`).subscribe(change => {
                    const data = change.snapshot.val();
                    setHasAddedToShopifyCart(_.get(data, 'added', false));
                    setOutOfStock(_.get(data, 'outOfStock', false));
                })
            });

        () => {
            if (sub) {
                sub.unsubscribe();
            }
        }

        
    }, [switcherFsId]);

    useEffect(() => {
        if (_.get(addToCartCheckerData, 'added') === true) {
            setHasAddedToShopifyCart(true);
        }
    }, [addToCartCheckerData])

    return (
        <UserSelectionContext.Provider value={{ 
            quantity, 
            setQuantity,
            material,
            setMaterial,
            generalOption,
            setGeneralOption,
            modelName,
            setModelName,
            variant,
            basePrice,
            totalAmount,
            selectedSection,
            setSelectedSection,
            sections,
            isSuccessDialogOpen,
            setIsSuccessDialogOpen,

            userImageUrls,
            imageIds,
            addUserImage,
            removeUserImage,

            hasFetchedAllData,
            hasAddedToShopifyCart,
            fsId,
            outOfStock,
            isOutOfStockDialogOpen,
            setIsOutOfStockDialogOpen,

            switchFsId
        }}>
            {children}
        </UserSelectionContext.Provider>
    )
}

export default UserSelectionContextProvider;