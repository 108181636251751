import React, { useContext } from 'react';
import { DeleteOutline } from '@material-ui/icons';
import Input from '../../../Input/Input';
import { EditModelContext } from '../../../../contexts/EditModelContext';
import Button from '../../../Button/button';
import Lock from '../../../Lock';
import { EditProductContext } from '../../../../contexts/EditProductContext';
import Boundary from '../../../Boundary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@material-ui/core';
import { rgbToString } from '../../../Customize/Customize';
import TextField from '@material-ui/core/TextField';


const EditText = () => {
    const {
        addText,
        texts,
        textIds,
        changeText,
        changeTextTitles,
        changeTextPlaceholder,
        textPlaceHolders,
        textTitles,
        removeText,
        textCharacterLimits,
        changeTextCharacterLimits
    } = useContext(EditModelContext);
    
    

    const { 
        toggleLockedItem, 
        isLocked, 
        toggleBoundaryItem, 
        hasBoundary,
        hasMonoColor,
        selectedBgIndex,
        colors
    } = useContext(EditProductContext);
    const color = colors[selectedBgIndex] ? colors[selectedBgIndex] : 'black';

    return (
        <div>
            {texts.map((text, index) => (
                <Accordion key={textIds[index]}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{textTitles[index]}</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ width: '100%', paddingRight: 8 }}>
                        <div>
                            <TextField style={{ width: '100%' }} title={textTitles[index]} value={text} onChange={(e) => changeText(index, e.target.value)} multiline placeholder={textPlaceHolders[index]}/>
                            <Input style={{ width: '100%' }} title='Title' value={textTitles[index]} setValue={(e) => changeTextTitles(index, e)} Title='Placeholder' />
                            <Input style={{ width: '100%' }} title='Placeholder' value={textPlaceHolders[index]} setValue={(e) => changeTextPlaceholder(index, e)} placeholder='Placeholder' />

                            <TextField
                                label="Limite de caractères"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ marginTop: 12 }}
                                variant="standard"
                                value={textCharacterLimits[index]}
                                onChange={(e) => changeTextCharacterLimits(index, e.target.value)}
                            />

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <div style={{ cursor: 'pointer', margin: '12px 0' }} onClick={() => toggleLockedItem(textIds[index])}>
                                    <Lock locked={isLocked(textIds[index])} />
                                </div>

                                <DeleteOutline onClick={() => removeText(index)} style={{ cursor: 'pointer' }} color="error" />

                                <div style={{ cursor: 'pointer' }} onClick={() => toggleBoundaryItem(textIds[index])}>
                                    <Boundary hasBoundary={hasBoundary(textIds[index])} />
                                </div>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            ))}
            <div style={{ marginTop: 16 }}>
                <Button onclick={() => addText(hasMonoColor ? rgbToString(color) : 'black')} name={'Ajouter un champ de texte'}></Button>
            </div>
        </div>
    );
}

export default EditText;
