import React from "react"
import Menu from "./components/menu"
import MenuContextProvider from "./contexts/MenuContext"
import { ThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { AppolloClientProvider } from './apollo/ApolloProvider';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Index from "./pages";
import CustomizePage from "./pages/customize";
import LoginPage from './pages/login';
import ModelsPage from "./pages/models";
import './App.css';
import ProductsPage from "./pages/products";
import ProductEditPage from "./pages/edit-product";
import EditProductContextProvider from "./contexts/EditProductContext";
import CustomizeProductPage from "./pages/CustomizeProductPage";
import EditModel from "./components/Models/EditModel";
import EditModelContextProvider from "./contexts/EditModelContext";
import UserSelectionContextProvider from "./contexts/UserSelectionContext";
import FontsPage from "./pages/fonts";
import { FontContextProvider } from "./contexts/FontContext";
import FinishedProduct from "./pages/FinishedProduct";
import WindowResolutionContextProvider from "./contexts/WindowResolutionContext";
import ChangePassword from "./pages/ChangePassword";
import UserCustomizeTextProvider from "./contexts/UserCustomizeTextContext";
import ProductsContextProvider from "./contexts/ProductsContext";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#37A2B4',
    },
  },
});

const App = () => {
  return (
    <Router>
      <WindowResolutionContextProvider>
        <AppolloClientProvider>
          <ThemeProvider theme={theme}>
            <MenuContextProvider>
              <ProductsContextProvider>
                <FontContextProvider>
                  <EditProductContextProvider>
                    <UserSelectionContextProvider>
                      <UserCustomizeTextProvider>
                        <Menu>
                          <Switch>
                            <Route exact path="/" component={ProductsPage} />
                            <Route exact path="/customize" component={CustomizePage} />
                            <Route exact path="/customize/:id" component={CustomizeProductPage} />
                            <Route exact path="/models" component={ModelsPage} />
                            <Route exact path="/products" component={ProductsPage} />
                            <Route exact path="/login" component={LoginPage} />
                            <Route exact path="/fonts" component={FontsPage} />
                            <Route exact path="/product/:id" component={ProductEditPage} />
                            <Route exact path="/add-model" component={EditModel} />
                            <Route exact path="/edit-model/:id" component={EditModel} />
                            <Route exact path="/finished/:id" component={FinishedProduct} />
                            <Route exact path="/change-password" component={ChangePassword} />

                            <Route component={ProductsPage} />
                          </Switch>
                        </Menu>
                      </UserCustomizeTextProvider>
                    </UserSelectionContextProvider>
                  </EditProductContextProvider>
                </FontContextProvider>
              </ProductsContextProvider>
            </MenuContextProvider>
          </ThemeProvider>
        </AppolloClientProvider>
      </WindowResolutionContextProvider>
    </Router>
  );
}

export default App;
