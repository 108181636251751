import { Box } from '@material-ui/core';
import React, { useContext } from 'react';
import { EditProductContext } from '../../contexts/EditProductContext';
import undoLeft from './../../assets/icons/undo_left.png';
import undoRight from './../../assets/icons/undo_right.png';

const UndoRedo = ({ isMobile = false }) => {
    const { undo, redo, canRedo, canUndo, undoRedoHistory, undoRedoIndex } = useContext(EditProductContext);

    return (
        <Box display='flex' className='right-header-container' style={{ borderBottom: !isMobile ? '1px solid #DADEE3' : 'none' }}>
            <button style={{ cursor: 'pointer', border: 'none', outline: 'none', borderRight: !isMobile ? '1px solid #DADEE3' : 'none' }} onClick={() => undo()}>
                <img src={undoLeft} alt="prev" style={{ opacity: canUndo ? 1 : 0.6 }} />
            </button>
            <button style={{ cursor: 'pointer', border: 'none', outline: 'none' }} onClick={() => redo()}>
                <img src={undoRight} alt="next" style={{ opacity: canRedo ? 1 : 0.6 }} />
            </button>
        </Box>
    )
}

export default UndoRedo;
