import { Dialog, DialogTitle, IconButton } from '@material-ui/core';
import React, { useContext } from 'react';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import './style.scss';
import Button from '../../../../components/Button/button';
import { Button as MaterialButton } from '@material-ui/core';
import { UserSelectionContext } from '../../../../contexts/UserSelectionContext';
import { EditProductContext } from '../../../../contexts/EditProductContext';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';


const OutOfStockDialog = () => {
    const { isOutOfStockDialogOpen, setIsOutOfStockDialogOpen } = useContext(UserSelectionContext);

    return (
        <Dialog aria-labelledby="simple-dialog-title" open={isOutOfStockDialogOpen} disableBackdropClick>
            <div className="ATCD">
                <div className="ATCD-center-child">
                    <ErrorOutlineIcon htmlColor="red" style={{ fontSize: 144 }} />
                </div>
                <div className="ATCD-success-text">Malheureusement, ce produit est en rupture de stock</div>

                <div className="ATCD-center-child" style={{ margin: 16 }}>
                    <MaterialButton color="primary" onClick={() => window.location.href = 'https://monzaya.fr'}>Continuer vos achats</MaterialButton>
                    <MaterialButton color="primary" onClick={() => setIsOutOfStockDialogOpen(false)}>Fermer</MaterialButton>
                </div>
            </div>
        </Dialog>
    );
}

export default OutOfStockDialog;
