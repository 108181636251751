import React, { useContext, useRef } from 'react';
import Input from '../../../components/Input/Input';
import { EditProductContext } from '../../../contexts/EditProductContext';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '../../../components/Button/button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Typography } from '@material-ui/core';
import * as _ from 'lodash';
import './style.scss';

const EditPages = () => {
    const {
        pageNames,
        changePageName,
        addPage,
        removePage,
        pageImageUrls,
        changePageImage,
        uploadingPageImage,
        pageIds
    } = useContext(EditProductContext);
    const inputFileRefs = _.range(50).map(d => useRef(null));

    return (
        <div>
            {pageNames.map((pageName, index) => (
                <Accordion key={pageIds[index]}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{pageName}</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ width: '100%' }}>
                        <div>
                            <div className="EditPages-image" style={{ backgroundImage: `url(${pageImageUrls[index]})` }}>
                                { uploadingPageImage[index] && <CircularProgress /> }
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button name="Télécharger une image" onclick={() => inputFileRefs[index].current.click()} />
                            </div>

                            <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', width: '100%', marginTop: 16 }}>
                                <Input
                                    value={pageName}
                                    setValue={text => changePageName(index, text)}
                                    placeholder="Page Nom"
                                />
                                <div style={{ padding: '8px 16px', cursor: 'pointer', marginRight: -16 }} onClick={() => removePage(index)}>
                                    <DeleteIcon color="error" />
                                </div>
                            </div>

                            <input
                                type="file"
                                ref={inputFileRefs[index]}
                                onChange={e => changePageImage(index, e.target.files[0])}
                                style={{ position: 'absolute', left: -10000, top: -10000 }}
                            />
                        </div>
                    </AccordionDetails>
                </Accordion>
            ))}

            <div style={{ marginTop: 16 }}></div>

            <Button name="Ajouter une page" onclick={addPage} />
        </div>
    )
}

export default EditPages;
