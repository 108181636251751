import React from 'react';
import { useUser } from '../../firebase/firebase';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const PrivateRoute: React.FC<any> = ({ children }) => {
    const history = useHistory();
    const { user, loading } = useUser();

    if (loading) {
        return <></> //<CircularProgress size={32} />
    }

    if (user === null) {
        history.push('/login');
        return <div />;
    }

    return children;
}

export default PrivateRoute;
