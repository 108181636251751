import { Paper, Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Checkbox, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import { ProductsContext } from '../../contexts/ProductsContext';
import * as _ from 'lodash';

const DuplicateDialog = ({ open, onDuplicate, duplicating }) => {
    const { loading, products } = useContext(ProductsContext);
    const [searchText, setSearchText] = useState('');
    const [productsToShow, setProductsToShow] = useState<any[]>([]);
    const [selectedId, setSelectedId] = React.useState('');

    useEffect(() => {
        if (_.get(products, 'products', []).length > 0) {
            setProductsToShow(
                // !searchText ? _.get(products, 'products', []) : 
                    _.get(products, 'products', [])
                        .filter(d => d.title.toLowerCase().startsWith(searchText.toLowerCase()))
                        .map(d => ({
                            name: d.title,
                            imageUrl: d.image?.src,
                            id: d.id,
                        }))
            )
        }
    }, [products, searchText]);

    const onSearchTextChange = (e) => {
        const val = e.target.value;
        setSearchText(val);
    }

    return (
        <Dialog aria-labelledby="simple-dialog-title" open={open} disableBackdropClick>
            <DialogTitle id="alert-dialog-title">
                {"Choisissez le produit à dupliquer"}
            </DialogTitle>
            <DialogContent>
                {loading || duplicating ? <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>  : (
                    <div>
                        <TextField value={searchText} onChange={onSearchTextChange} label='Rechercher un produit' style={{ width: '100%' }} />
                        <TableContainer style={{ height: 400, width: 500 }} component={Paper}>
                            <Table stickyHeader>
                                <TableBody>
                                {productsToShow.map((row) => (
                                    <TableRow
                                    key={row.id}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => setSelectedId(row.id)}
                                    >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            checked={row.id === selectedId}
                                            onChange={() => setSelectedId(row.id)}
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <img src={row.imageUrl} style={{ width: 64 }} />
                                    </TableCell>
                                    <TableCell align="left">{row.name}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                {!loading && !duplicating && selectedId && (
                    <Button onClick={() => onDuplicate(selectedId)}>
                        Dupliquer
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default DuplicateDialog;
