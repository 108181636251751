import React, { useState, useEffect, useContext } from 'react'
import { useFirestoreCollection } from '../firebase/firebase'
import TopBar from '../components/Models/topBar';
import Table from '../components/Fonts/table/table'
import PrivateRoute from './../components/PrivateRoute';
import InsertFont from '../components/Fonts/insertFonts/insertFont';
import { FontContext } from '../contexts/FontContext';


const FontsPage = () => {
    const [addClicked, setAddClicked] = useState<Boolean>(false)
    const [menusList, setMenuList] = useState<Array<string>>(['Fonts'])
    let { data, loading } = useFirestoreCollection(['fonts'])
    const [searchValue, setSearchValue] = useState<string>(null)
    const { addFont } = useContext(FontContext);

    useEffect(() => {
        if (addClicked) setMenuList([...menusList, 'Ajouter un nouveau fonts'])
        else setMenuList(['fonts'])
    }, [addClicked])
    
    if (loading) {
        return <h1>Loading...</h1>;
    }
    return (
        <PrivateRoute>
            <TopBar link={'fonts'} searchValue={searchValue} setSearchValue={setSearchValue} menuList={menusList} addClicked={addClicked} setAddClicked={setAddClicked}></TopBar>
            {!addClicked ? <Table data={data}></Table> : <InsertFont setAddClicked={setAddClicked}></InsertFont>}
        </PrivateRoute>
    )
}


export default FontsPage;