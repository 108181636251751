import { Slider } from '@material-ui/core';
import React from 'react';
import ZoomOut from './../../../assets/icons/zoom_out.svg';
import ZoomIn from './../../../assets/icons/zoom_in.svg';
import './style.scss';

const ZOOM_MIN = 0;
const ZOOM_MAX = 1;
const ZOOM_STEP = 0.1;

const Zoom = ({ zoomValue, onZoomChange }) => {
    return (
        <div className="Zoom">
            <div className="Zoom-button" onClick={() => onZoomChange(null, Math.max(ZOOM_MIN, zoomValue - ZOOM_STEP))}>
                <img src={ZoomOut} alt="" />
            </div>
            <div className="Zoom-slider">
                <Slider
                    value={zoomValue}
                    onChange={onZoomChange}
                    min={ZOOM_MIN}
                    max={ZOOM_MAX}
                    step={ZOOM_STEP}
                />
            </div>
            <div className="Zoom-button" onClick={() => onZoomChange(null, Math.min(ZOOM_MAX, zoomValue + ZOOM_STEP))}>
                <img src={ZoomIn} alt="" />
            </div>
        </div>

    )
}

export default Zoom;
