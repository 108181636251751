import { fabric } from 'fabric'
import FontFaceObserver from "fontfaceobserver"

const getAngle = (radius, l) => {
    let cLength = Math.PI * radius * 2;
    let angle = l * 360 / cLength;
    return angle;
}

const getRadian = (angle) => {
    if(angle < 0) angle = -angle
    return angle * Math.PI / 180;
}

const getTextWidth = (group) => {
    let x = group._objects.reduce((acc, elm) => acc + elm.width + (elm.charSpacing ? elm.charSpacing : 0), 0)
    x -= group._objects[group._objects.length-1].width
    return x
}

export const getText = (group) => {
    if(!group.text) {
        if(!group._objects) return
        group.text = group._objects.reduce((acc, val) => {
            return acc + (val.text ? val.text : '')
        }, '')
        if(group.text.length == 0) group.text = null
    }
    return group.text
}

const changeAngle = (group, circleAngle) => {
    if (!group || group.multipleLine) return
    if(!circleAngle) {
        if(group.circleAngle) {
            group._objects.forEach(elm => {
                elm.set('top', 0)
                elm.set('angle', 0)
                group.set('height', elm.height) 
            })
        }
        group.set('circleAngle', circleAngle);
        getSetWidth(group)
        changeAlignment(group, group.alignment)
        return
    }
    group.set('circleAngle', circleAngle);
    const initAngle = circleAngle
    let radius = Math.abs((getTextWidth(group)+group._objects[group._objects.length-1].width)/(circleAngle*Math.PI/180));
    let height = 2 * radius + 15, width = 2 * radius + 15, dis = 0, reversed = 0, rr = Math.abs(circleAngle) > 180 ? 0 : 8;
    if(circleAngle < 0) {
        circleAngle = -circleAngle, reversed = 1
    }

    if (circleAngle >= 0 && circleAngle <= 180) {
        width = 2 * Math.sin(getRadian(circleAngle / 2)) * radius + 15;
        height = radius - Math.cos(getRadian(circleAngle / 2)) * radius + 15;
        dis = radius - height / 2;
    } else {
        dis = (radius - Math.cos(getRadian((360 - circleAngle) / 2)) * radius) / 2;
        height = radius + Math.cos(getRadian((360 - circleAngle) / 2)) * radius + 15;
    } 

    group.set('width', width)
    group.set('height', height)
    let k = (180 - circleAngle)
    circleAngle = circleAngle / 2 + 90;
    if(reversed) {
        circleAngle += k, dis = -dis, rr = -rr;
    }
    
    circleAngle = circleAngle - Math.floor(circleAngle/360)*360
    let d = group.item(0).width/2;
    // if(group._objects.length > 1) d += group._objects[1].width/2

    circleAngle +=  (reversed ? 1 : -1)*(getAngle(radius, d))
    group._objects.forEach((obj, i) => {
        let top = 0, left = 0;
        if (circleAngle >= 0 && circleAngle <= 90) top = -Math.sin(getRadian(circleAngle)), left = Math.cos(getRadian(circleAngle));
        else if (circleAngle < 180) top = -Math.sin(getRadian(180 - circleAngle)), left = -Math.cos(getRadian(180 - circleAngle));
        else if (circleAngle < 270) top = Math.sin(getRadian(circleAngle - 180)), left = -Math.cos(getRadian(circleAngle - 180));
        else top = Math.sin(getRadian(360 - circleAngle)), left = Math.cos(getRadian(360 - circleAngle));

        top *= radius;
        left *= radius;
        top += dis;

        obj.set('circleAngle', initAngle)
        obj.set('left', left)
        obj.set('top', top + rr)
        obj.set('angle', reversed ? 270 - circleAngle : 90 - circleAngle)
        let d = obj.width/2
        if(i+1 < group._objects.length) d += group._objects[i+1].width/2
        circleAngle += (reversed ? 1 : -1)* getAngle(radius, d + (obj.charSpacing ? obj.charSpacing : 0))
        if(circleAngle > 360) circleAngle -= 360;        
    })
}

const changeStyle = (group, opts, first = false) => {
    let width = 0, top = 0, height = 0, multipleLine = false, ind = 0;
    group.text.split('').forEach((c, i) => {
        if(c == '\n') {
            top += height
            width = 0
            multipleLine = true;
            return
        }
        if (!group.item(ind)) {
            const letter = new fabric.Text(c, {
                ...opts,
                left: width,
                top: top,
                fontSize: group.fontSize ? group.fontSize : 20,
                centerRotation: true,
                originX: 'cetner',
                originY: 'center',
                fontFamily: 'default',
            })
            width += letter.width
            height = letter.height
            group.addWithUpdate(letter)
        } else {
            group.item(ind).text = c;
            group.item(ind).left = width
            width += group.item(ind).width
            group.item(ind).top = top;
        }
        ind++
    })
    group.set('left', 150)
    group.set('top', 50)
    group.set('multipleLine', multipleLine)
    group.set('alignment', 'center')
    if(first ||  !group.circleAngle) changeAlignment(group, group.alignment)
    else changeAngle(group, opts.angle)

}

const change = (elm, type, value) => {
    if (type == 1) 
        elm.fontWeight == "normal"
            ? elm.set("fontWeight", value)
            : elm.set("fontWeight", "normal")
    if (type == 2)
        elm.fontStyle == "normal"
            ? elm.set("fontStyle", value)
            : elm.set("fontStyle", "normal")
    if (type == 3) elm.set("underline", !elm.underline)
    if (type == 6) elm.set("fill", value)
    if (type == 7) elm.set('charSpacing', value)
}

const getSetWidth = (group) => {
    let sum = 0, mxWidth = 0, ind = 0
    group.text.split('').forEach(c => {
        if(c === '\n') {
            mxWidth = mxWidth < sum ? sum : mxWidth;
            sum = 0
            return
        }
        let letter = group.item(ind)
        sum += letter.width + (group.charSpacing ? group.charSpacing : 0)
        ind++
    })
    mxWidth = mxWidth < sum ? sum : mxWidth
    group.set('width', mxWidth)
    return mxWidth
}

export const changeTextStyle = async (group, type, value) => {
    if (!group || !group.text) return
    let ind = 0;
    if (type == 4) {
        const { fontFamily, fontFileName, fontUrl, fontSize } = value;
        if(fontFamily != 'default') {
            await fabric.util.clearFabricFontCache(fontFamily)
            let f = new FontFaceObserver(fontFamily)
            await f.load(null, 100000)
        }
        let width = 0, mxWidth = 0;
        group.text.split('').forEach(c => {
            if(c === '\n') {
                mxWidth = mxWidth > width ? mxWidth : width;
                return
            }
            group.item(ind).set("fontFamily", fontFamily)
            fontFileName && (group.item(ind).fontFileName = fontFileName);
            fontUrl && (group.item(ind).fontUrl = fontUrl);
            width += group.item(ind).width + (group.charSpacing ? group.charSpacing : 0)
            ind++
        })
        mxWidth = mxWidth > width ? mxWidth : width;
        group.set('fontFamily', fontFamily)
        group.set('fontSize', fontSize);

        fontFileName && (group.fontFileName = fontFileName);
        fontUrl && (group.fontUrl = fontUrl);

        if(group.multipleLine || !group.circleAngle) {
            getSetWidth(group)
            changeAlignment(group, group.alignment)
            changeLineSpace(group, group.lineSpace)
        } 
        if(group.circleAngle) changeAngle(group, group.circleAngle)
        return
    }
    group.text.split('').forEach(c => {
        if(c === '\n') return
        let letter = group.item(ind)
        ind++
        if(type < 9) change(letter, type, value), change(group, type, value)
        if (type == 9) changeAngle(group, value);
    })
    if(type == 7) {
        if(group.circleAngle) changeAngle(group, group.circleAngle)
        else getSetWidth(group)
    }
    if(!group.circleAngle) changeAlignment(group, group.alignment)
    else changeAngle(group, group.circleAngle)
    changeLineSpace(group, type === 8 ? value : group.lineSpace)
}

export const copyStyle = (x, y) => {
    x.set('fontSize', y.fontSize ? y.fontSize : 20)
    x.set('fill', y.fill)
    x.set('fontWeight', y.fontWeight ? y.fontWeight : 'normal')
    x.set('fontStyle', y.fontStyle ? y.fontStyle : 'normal')
    x.set('underline', y.underline ? y.underline : false)
    x.set('fontFamily', y.fontFamily ? y.fontFamily : 'Arial')
    // x.set('circleAngle', y.circleAngle ? y.circleAngle : x.circleAngle)
    x.set('lineSpace', y.lineSpace ? y.lineSpace : x.lineSpace)
    x.set('charSpacing', y.charSpacing ? y.charSpacing : x.charSpacing)
}

export const changeAlignment = (group, alignment) => {
    if(group.circleAngle) return;
    getSetWidth(group)
    group.set('alignment', alignment)
    let maxWidth = group.width, left = 0, ind = 0, width = 0, lineIndex = 0;
    const lines = [];
    
    group.text.split('').forEach(c => {
        if(c === '\n') {
            if(!group.item(ind)) return
            lines.push(width)
            width = 0
            return
        }
        group.item(ind).set('lineNum', lines.length)
        width += group.item(ind).width + (group.charSpacing ? group.charSpacing : 0)
        ind++
    })
    
    lines.push(width)
    ind = 0
    if(alignment === 'right') left = (maxWidth - lines[0]) - (maxWidth/2) + group.item(0).width/2
    else if(alignment === 'center') left = (maxWidth - lines[0])/2 - (maxWidth/2) + group.item(ind).width/2
    else left = -maxWidth/2 + group.item(ind).width/2
    group.text.split('').forEach(c => {
        if(c === '\n') {
            if(!group.item(ind)) return
            lineIndex++

            if(alignment === 'right') left = maxWidth - lines[lineIndex] - (maxWidth/2) + group.item(ind).width/2
            else if(alignment === 'center') left = (maxWidth - lines[lineIndex])/2 - (maxWidth/2) + group.item(ind).width/2
            else left = -maxWidth/2 + group.item(ind).width/2
            return
        }

        group.item(ind).set('left', left)
        left += group.item(ind).width/2 + (group.charSpacing ? group.charSpacing : 0)
        if(group.item(ind+1)) left += group.item(ind+1).width/2
        ind++
    })
}

const changeLineSpace = (group, lineSpace) => {
    if(group.circleAngle || !group.multipleLine) return
    group.set('lineSpace', lineSpace ? lineSpace : 0)
    let height = 0, ind = 0, x = 0, charHeight = group.item(0).height;
    group.text.split('').forEach(c => {
        if(c === '\n') {
            if(!group.item(ind)) return
            x += lineSpace + charHeight
            height = x
            return
        }
        ind++
    })
    height += charHeight
    ind = 0
    x = -(height/2 - charHeight/2)
    group.text.split('').forEach(c => {
        if(c === '\n') {
            if(!group.item(ind)) return
            x += lineSpace + charHeight
            return
        }
        group.item(ind).set('top', x)
        ind++
    })

    group.set('height', height)
    return
}


export const changeText = async (group, text) => {
    let x = group.left, top = group.top, charHeight = 0, tFirst = group.top, maxWidth = 0, multipleLine = false;
    if(group.text == text) return
    const angle = group.angle, scaleX = group.scaleX, scaleY = group.scaleY;
    const { fill } = group;
    group.set('text', text)
    let left = 0;
    if (typeof group.size != 'function') {
        return;
    }
    while(group.size() > 0) group.removeWithUpdate(group.item(group.size()-1))
    if(!group.text) return
    // // let ind = 0;
    // // const splitted = group.text.split('');
    // for (let i = 0; i < group.text.length; i++) {
    //     var c = group.text[i];
    //     if(c === '\n') {
    //         top += charHeight;
    //         left = 0
    //         multipleLine = true
    //         return
    //     }
    //     const groupItem = group.item(i);
        
    //     if (!groupItem) {
    //         const letter = new fabric.Text(c, {
    //             left: left,
    //             top: top,
    //             centerRotation: true,
    //             originX: 'center',
    //             originY: 'center',
    //             fontSize: group.fontSize ? group.fontSize : 20,
    //             fill: group.fill,
    //             fontWeight: group.fontWeight ? group.fontWeight : 'normal',
    //             fontStyle: group.fontStyle ? group.fontStyle : 'normal',
    //             underline: group.underline ? group.underline : false,
    //             fontFamily: group.fontFamily ? group.fontFamily : 'Arial',
    //         })
    //         left += letter.width + (group.charSpacing ? group.charSpacing : 0)
    //         // group.addWithUpdate(letter)
    //         group.addWithUpdate(letter)
    //         charHeight = letter.height
    //     } else {
    //         charHeight = groupItem.get('height')
    //         groupItem.set('text', c)
    //         groupItem.set('left', left)
    //         groupItem.set('top', top)
    //         left += groupItem.width + (group.charSpacing ? group.charSpacing : 0)
    //     }
    //     maxWidth = maxWidth < left ? left : maxWidth
    //     // i++
    // }
    let ind = 0;
    group.text.split('').forEach(c => {
        if(c === '\n') {
            top += charHeight;
            left = 0
            multipleLine = true
            return
        }
        
        if (!group.item(ind)) {
            const letter = new fabric.Text(c, {
                left: left,
                top: top,
                centerRotation: true,
                originX: 'center',
                originY: 'center',
                fontSize: group.fontSize ? group.fontSize : 20,
                fill: group.fill,
                fontWeight: group.fontWeight ? group.fontWeight : 'normal',
                fontStyle: group.fontStyle ? group.fontStyle : 'normal',
                underline: group.underline ? group.underline : false,
                fontFamily: group.fontFamily ? group.fontFamily : 'Arial',
            })
            left += letter.width + (group.charSpacing ? group.charSpacing : 0)
            group.addWithUpdate(letter)
            charHeight = letter.height
        } else {
            charHeight = group.item(ind).get('height')
            group.item(ind).set('text', c)
            group.item(ind).set('left', left)
            group.item(ind).set('top', top)
            left += group.item(ind).width + (group.charSpacing ? group.charSpacing : 0)
        }
        maxWidth = maxWidth < left ? left : maxWidth
        ind++
    })
    group.set('multipleLine', multipleLine)
    if(multipleLine) group.set('circleAngle', 0);
    group.set('top', tFirst)
    group.set('left', x)
    group.set('width', maxWidth)
    group.set('angle', angle)
    group.set('scaleX', scaleX)
    group.set('scaleY', scaleY)
    if(!group.circleAngle) changeAlignment(group, group.alignment), changeLineSpace(group, group.lineSpace)
    changeAngle(group, group.circleAngle)
}

export const CurvedText = (text: string, opts: any) => {
    const group = new fabric.Group([], {
        top: 100, left: 50,
        originX: 'center',
        originY: 'center',
        text: text,
    })
    group.set('fontFamily', 'default')
    changeStyle(group, opts, true)
    return group;
}