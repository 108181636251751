import React, { createContext, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { addDoc, upload, writeDoc } from '../firebase/firebase';
import { CreateGroup, RemoveObjectById, Def, AddModelImage, ChangeModelColor, rgbToString, ResetModelColor, ChangeTextColor, AddUserImageBoundary, RemoveUserImageBoundary } from '../components/Customize/Customize';
import { EditProductContext } from './EditProductContext';
import { Color } from 'material-ui-color';
import { DEFAULT_MODEL_COLOR } from '../components/Models/EditModel/EditModelColor';



export const EditModelContext = createContext<{
    modelId: string;
    modelImageUrl: string;
    setModelImageUrl: Function;
    modelName: string;
    setModelName: Function;

    addText: Function;
    removeText: Function;
    changeText: Function;
    texts: string[];
    textIds: string[];
    textPlaceHolders: string[];
    textTitles: string[];
    changeTextPlaceholder: Function;
    changeTextTitles: Function;
    saving: boolean;
    setSaving: Function;
    addModelImage: Function;
    uploading: boolean;
    modelItemIds: string[];
    modelImageId: string;

    uploadingPreview: boolean;
    previewImageUrl: string;
    changePreviewImage: Function;

    textCharacterLimits: number[];
    changeTextCharacterLimits: Function;

    hasImages: boolean;
    turnOnImage: Function;
    turnOffImage: Function;
    getImageBoundaryId: Function;
}>(null)


const EditModelContextProvider = ({ children, id }) => {
    // const { id: modelId } = useParams<any>();
    const modelId = id;
    const [modelImageUrl, setModelImageUrl] = useState<string>(null);
    const [modelName, setModelName] = useState<string>('');
    const [texts, setTexts] = useState<string[]>([]);
    const [textIds, setTextIds] = useState<string[]>([]);
    const [textCharacterLimits, setTextCharacterLimits] = useState<number[]>([]);
    const [textPlaceHolders, setTextPlaceHolders] = useState<string[]>([]);
    const [textTitles, setTextTitles] = useState<string[]>([]);
    const [saving, setSaving] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [modelItemIds, setModelItemIds] = useState<string[]>([]);

    const [hasImages, setHasImages] = useState(false);

    const [previewImageUrl, setPreviewImageUrl] = useState<string>(null);
    const [uploadingPreview, setUploadingPreview] = useState(false);
    
    const isNewModel = modelId == null;
    const { 
        updateModelData, 
        models, 
        selectedModelId, 
        changeTextById,
        hasMonoColor,
        colors,
        selectedBgIndex
    } = useContext(EditProductContext);
    const color = colors[selectedBgIndex] != null ? colors[selectedBgIndex] : DEFAULT_MODEL_COLOR;    

    const turnOnImage = () => {
        setHasImages(true);
        AddUserImageBoundary(getImageBoundaryId(modelId));
    }

    const turnOffImage = () => {
        setHasImages(false);
        RemoveUserImageBoundary(getImageBoundaryId(modelId));
    }

    const getImageBoundaryId = (modelId: string) => {
        return `zurag-zuraas-model-${modelId}`;
    }
    
    const modelImageId = `model-${modelId}-image`;

    useEffect(() => {
        if (models == null || models[modelId] == null) {
            return;
        }

        const modelData = models[modelId];
        if (_.get(modelData, 'modelImageUrl')) {
            setModelImageUrl(modelData['modelImageUrl']);
        }
        if (_.get(modelData, 'modelName')) {
            setModelName(modelData['modelName']);
        }
        if (_.get(modelData, 'texts')) {
            setTexts(modelData['texts']);
        }
        if (_.get(modelData, 'textIds')) {
            setTextIds(modelData['textIds']);
        }
        if (_.get(modelData, 'textPlaceHolders')) {
            setTextPlaceHolders(modelData['textPlaceHolders']);
        }
        if (_.get(modelData, 'textTitles')) {
            setTextTitles(modelData['textTitles']);
        }
        if (_.get(modelData, 'hasMonoColor')) {
            // setHasMonoColor(_.get(modelData, 'hasMonoColor'));
        }
        if (_.get(modelData, 'color')) {
            // setColor(_.get(modelData, 'color'));
        }
        if (_.get(modelData, 'previewImageUrl')) {
            setPreviewImageUrl(_.get(modelData, 'previewImageUrl'))
        }
        if (_.get(modelData, 'textCharacterLimits')) {
            setTextCharacterLimits(modelData['textCharacterLimits']);
        } else if (_.get(modelData, 'textIds')) {
            setTextCharacterLimits(_.range(_.get(modelData, 'textIds').length).map(d => 0))
        }
        if (_.get(modelData, 'hasImages')) {
            setHasImages(_.get(modelData, 'hasImages', false));
        }
    }, [modelId]);

    useEffect(() => {
        if (modelId == null) {
            return;
        }

        updateModelData(modelId, {
            modelId,
            modelImageUrl,
            modelName,
            texts,
            textIds,
            textPlaceHolders,
            textTitles,
            color,
            hasMonoColor,
            previewImageUrl,
            textCharacterLimits,
            hasImages
        });
    }, [modelId, hasImages, modelImageUrl, modelName, texts, textIds, textPlaceHolders, textTitles, color, hasMonoColor, previewImageUrl, textCharacterLimits])

    // const changeColor = (color) => {
    //     ChangeModelColor(modelId, color);
    //     setColor(color);
    // }

    // const resetColor = () => {
    //     ResetModelColor(modelId);
    // }

    const addModelImage = async (file: any) => {
        setUploading(true);
        const url = await upload(file);
        AddModelImage(modelImageId, url, selectedModelId === modelId, hasMonoColor ? rgbToString(color) : null);
        setModelItemIds([...modelItemIds, modelImageId]);
        setModelImageUrl(url);
        setUploading(false);
    }

    const changePreviewImage = async (file: any) => {
        setUploadingPreview(true);
        const url = await upload(file);
        setPreviewImageUrl(url);
        setUploadingPreview(false);
    }

    const addText = (color = DEFAULT_MODEL_COLOR) => {
        const id = `model-${modelId}-text-${uuid()}`;
        const defaultText = 'Modifier le texte';
        Def.addText(defaultText, {}, id, color);
        setTexts([...texts, defaultText]);
        setTextIds([...textIds, id]);
        setTextPlaceHolders([...textPlaceHolders, 'Placeholder']);
        setTextTitles([...textTitles, 'Title']);
        setModelItemIds([...modelItemIds, id]);
        setTextCharacterLimits([...textCharacterLimits, 0]);
    }

    const removeText = (index: number) => {
        texts.splice(index, 1);
        const [id] = textIds.splice(index, 1);
        textPlaceHolders.splice(index, 1);
        textTitles.splice(index, 1);

        RemoveObjectById(id);
        setTexts([...texts]);
        setTextIds([...textIds]);
        setTextPlaceHolders([...textPlaceHolders]);
        setTextTitles([...textTitles]);
        setModelItemIds(modelItemIds.filter(d => d !== id));
    }

    const changeText = (index: number, text: string) => {
        if (text.length === 0) {
            text = ' ';
        }
        if (text.length === 2 && text[0] === ' ') {
            text = text.slice(1, text.length);
        }
        const id = textIds[index];
        setTexts([
            ...texts.map((d, i) => i === index ? text : d)
        ]);
        changeTextById(id, text);

        ChangeTextColor(id, color);
    }

    const changeTextPlaceholder = (index: number, text: string) => {
        textPlaceHolders[index] = text;
        setTextPlaceHolders([...textPlaceHolders]);
    }

    const changeTextTitles = (index: number, text: string) => {
        textTitles[index] = text;
        setTextTitles([...textTitles]);
    }

    const changeTextCharacterLimits = (index: number, limit: number) => {
        if (textCharacterLimits && textCharacterLimits.length - 1 < index) {
            return;
        }
        textCharacterLimits[index] = limit;
        setTextCharacterLimits([...textCharacterLimits]);
    }

    return <EditModelContext.Provider value={{
        modelId,
        modelImageUrl,
        setModelImageUrl,
        modelName, 
        setModelName,
        addText,
        removeText,
        changeText,
        changeTextPlaceholder,
        changeTextTitles,
        textIds,
        texts,
        textTitles,
        textPlaceHolders,
        saving,
        setSaving,
        addModelImage,
        uploading,
        modelItemIds,
        modelImageId,

        previewImageUrl,
        changePreviewImage,
        uploadingPreview,
        changeTextCharacterLimits,
        textCharacterLimits,

        hasImages,
        turnOffImage,
        turnOnImage,
        getImageBoundaryId
    }}>
        {children}
    </EditModelContext.Provider>
}

export default EditModelContextProvider;