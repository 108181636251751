import React, { useContext } from 'react';
import Input from '../../../components/Input/Input';
import { EditProductContext } from '../../../contexts/EditProductContext';
import { ReactComponent as ArrowDownSVG } from './../../../assets/icons/arrow_down.svg';


const EditGeneral = () => {
    const { personalisationPrices, setSelectedPersonalisationPriceId, selectedPersonalisationPriceId } = useContext(EditProductContext);

    const onPersonalisionPriceChange = (e: any) => {
        setSelectedPersonalisationPriceId(e.target.value);
    }

    return (
        <div>
            <div className="UserCustomizeGeneral-label">Prix de la personnalisation <span style={{ color: 'red' }}>*</span></div>
            <div style={{ position: 'relative' }}>
                <select className="UserCustomizeGeneral-select" onChange={onPersonalisionPriceChange}>
                    {personalisationPrices.map(d => (
                        <option value={d.variantId} key={d.variantId} selected={selectedPersonalisationPriceId == d.variantId}>{d.title}</option>
                    ))}
                </select>
                <ArrowDownSVG style={{ position: 'absolute', right: 12, top: '50%', transform: 'translateY(-50%)' }} />
            </div>
        </div>
    )
}

export default EditGeneral;
