import React, { useContext } from 'react';
import Button from "@material-ui/core/Button"
import { EditProductContext } from '../../contexts/EditProductContext';
import Input from '../../components/Input/Input';
import { DeleteOutline } from '@material-ui/icons';
import { EditModelContext } from '../../contexts/EditModelContext';

const EditText = () => {
    const { 
        addText, 
        texts, 
        textIds, 
        changeText,
        changeTextTitles,
        changeTextPlaceholder,
        textPlaceholders,
        textTitles, 
        removeText,
        hasMonoColor, 
        colors,
        selectedBgIndex
    } = useContext(EditProductContext);

    const color = colors[selectedBgIndex] ? colors[selectedBgIndex] : 'black';

    return (
        <div>
            {texts.map((text, index) => (
                <div style={{paddingBottom: 12, marginBottom: 12, borderBottom: '1px solid black'}}>
                    <Input title={textTitles[index]} value={text} setValue={(e) => changeText(index, e)}  placeholder={textPlaceholders[index]} />
                    <Input title='Title' value={textTitles[index]} setValue={(e) => changeTextTitles(index, e)} Title='Placeholder' />
                    <Input title='Placeholder' value={textPlaceholders[index]} setValue={(e) => changeTextPlaceholder(index, e)} placeholder='Placeholder' />

                    <DeleteOutline onClick={() => removeText(index)} style={{ cursor: 'pointer', marginTop: 12 }} color="error" />
                </div>
            ))}
            <Button style={{marginTop: 16}} onClick={() => {
                addText(hasMonoColor ? color : 'black')
            }}>Ajouter un champ de texte</Button>
        </div>
    );
}

export default EditText;
