import React, { useContext, useEffect, useState } from 'react';
import { EditProductContext } from '../../../contexts/EditProductContext';
import _ from 'lodash';
import './style.scss';
import { ReactComponent as ArrowDownSVG } from './../../../assets/icons/arrow_down.svg';
import { UserSelectionContext } from '../../../contexts/UserSelectionContext';


const UserCustomizeGeneral = () => {
    const { product } = useContext(EditProductContext);
    const { quantity, setQuantity, setGeneralOption } = useContext(UserSelectionContext);
    const generalOption = _.find(_.get(product, 'options') || [], (d) => d.name !== 'Matériel' && d.name !== 'Modéle');

    const onGeneralOptionChange = (e: any) => {
        setGeneralOption(e.target.value);
    }

    useEffect(() => {
        generalOption && generalOption.values && generalOption.values[0] && setGeneralOption(generalOption.values[0]);
    }, [generalOption, product]);

    // if (!generalOption) {
    //     return <div></div>;
    // }

    return (
        <div className="UserCustomizeGeneral">
            {generalOption && (
                <div style={{ marginBottom: 28 }}>
                <div className="UserCustomizeGeneral-label">{generalOption.name} <span style={{ color: 'red' }}>*</span></div>
                <div style={{ position: 'relative' }}>
                    <select className="UserCustomizeGeneral-select" onChange={onGeneralOptionChange}>
                        {generalOption.values.map(value => (
                            <option value={value} key={value}>{value}</option>
                        ))}
                    </select>
                    <ArrowDownSVG style={{ position: 'absolute', right: 12, top: '50%', transform: 'translateY(-50%)' }} />
                </div>
            </div>
            )}

            <div>
                <div className="UserCustomizeGeneral-label">Quantité <span style={{ color: 'red' }}>*</span></div>
                <div className="UserCustomizeGeneral-quantity">
                    <div className="UserCustomizeGeneral-quantity-sign" onClick={() => setQuantity(Math.max(quantity - 1, 1))}>-</div>
                    <div className="UserCustomizeGeneral-quantity-number">{quantity}</div>
                    <div className="UserCustomizeGeneral-quantity-sign" onClick={() => setQuantity(Math.min(quantity + 1, 99))}>+</div>
                </div>
            </div>
        </div>
    )
}

export default UserCustomizeGeneral;
