import React, { createContext, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { EditProductContext } from './EditProductContext';
import { useApi } from '../hooks/useApi';


export const ProductsContext = createContext<{
    products: any[];
    loading: boolean;
}>(null)


const ProductsContextProvider = ({ children }) => {
    const { loading, data: products } = useApi('/getAllProducts');

    return <ProductsContext.Provider value={{
        products,
        loading
    }}>
        {children}
    </ProductsContext.Provider>
}

export default ProductsContextProvider;