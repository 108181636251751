import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import Input from '../../../components/Input/Input';
import { EditProductContext } from '../../../contexts/EditProductContext';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { Button, CircularProgress, IconButton } from '@material-ui/core';
import './UserCustomizeText.scss';
import { UserCustomizeTextContext } from '../../../contexts/UserCustomizeTextContext';

const UserCustomizeText = () => {
    const { models, selectedModelId, userChangeText, userRemoveText, isCanvasLoaded, userAddText } = useContext(EditProductContext);
    const { texts = [], textPlaceHolders = [], textTitles = [], textCharacterLimits = [] } = _.get(models, [selectedModelId], {});
    // const { texts, changeUserText } = useContext(UserCustomizeTextContext);
    const [maxTextLines, setMaxTextLines] = useState(-1);

    useEffect(() => {
        if (isCanvasLoaded) {
            setMaxTextLines(texts.length);
        }
    }, [isCanvasLoaded]);

    if (!isCanvasLoaded) {
        return <CircularProgress />;
    }

    return (
        <div>
            {texts.map((d, index) => (
                <div className="UserCustomizeText-hover" style={{ position: 'relative' }} >
                    <Input 
                        title={textTitles[index]} 
                        placeholder={textPlaceHolders[index]} 
                        value={texts[index]} 
                        setValue={text => {
                            userChangeText(selectedModelId, index, text);
                            // changeUserText(index, text);
                        }} 
                        maxCharacter={textCharacterLimits[index]}
                    />
                    {texts.length > 1 && (
                        <div className="UserCustomizeText-remove-icon">
                            <IconButton onClick={() => userRemoveText(selectedModelId, index)}>
                                <RemoveCircleOutlineIcon color="error" />
                            </IconButton>
                        </div>
                    )}
                </div>
            ))}

            {texts.length < maxTextLines && (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                    <Button onClick={() => userAddText(selectedModelId)} variant="contained" color="primary">Ajouter une ligne de texte</Button>
                </div>
            )}
        </div>
    );
}

export default UserCustomizeText;
