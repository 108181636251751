import React, { useState, useEffect, useLayoutEffect, useRef, useContext } from "react"
import { withStyles } from "@material-ui/core/styles"
import { fabric } from "fabric"
import MenuItem from "@material-ui/core/MenuItem"
import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import { createStyles, makeStyles, Theme, styled } from "@material-ui/core/styles"
import "./style.scss"
import { CurvedText, changeTextStyle, changeText, getText, copyStyle, changeAlignment } from './group'
import Menu, { MenuProps } from "@material-ui/core/Menu"
import Slider from '@material-ui/core/Slider';
import { CircularProgress, Grid, TextField } from "@material-ui/core"
import { ArrowDropDown, ArrowDropUp, EditLocation, ZoomIn } from '@material-ui/icons';
import { EditProductContext, IUndoRedoType } from "../../contexts/EditProductContext"
import * as _ from 'lodash';
import { FontContext } from "../../contexts/FontContext"
import { MenuContext } from "../../contexts/MenuContext"
// import LeftAlign from './left-align.svg';
// import CenterAlign from './center-align.svg';
// import RightAlign from './right-align.svg';
// import Bold from './Bold.svg'
// import Italie from './Italie.svg'
// import Usvg from './U.svg' 
// import Texte from './Texte.svg'
import LeftAlign from './../../assets/icons/new/gauche-1.svg';
import CenterAlign from './../../assets/icons/new/centre-1.svg';
import RightAlign from './../../assets/icons/new/droite-1.svg';

import Bold from './../../assets/icons/new/Bold.svg';
import Italie from './../../assets/icons/new/itali.svg';
import Usvg from './../../assets/icons/new/U-1.svg';
import Texte from './../../assets/icons/new/Texte.svg';
import Zoom from "./Zoom"
import UndoRedo from "../UndoRedo"
import { WindowResolutionContext } from "../../contexts/WindowResolutionContext"
import { isObjectLike } from "lodash"

const fonts = [
    "MrDeHaviland-Regular",
    "Limelight-Regular",
    "OdibeeSans-Regular",
    "ZCOOLKuaiLe-Regular",
]

const CANVAS_HEIGHT = 900;
const CANVAS_WIDTH = 900;
const MenuPropsCustom = {
    PaperProps: {
        style: {
            maxHeight: 400
        },
    },
};

export const Def
    // : {
    //   canvas: fabric.Canvas, 
    //   rect: any, 
    //   backgroundImageUrl: any, 
    //   lines: any, 
    //   setValue: any, 
    //   setText: any, 
    //   get: any, 
    //   init: any, 
    //   events: any,
    //   drawLine: any,
    //   clearLines: any,
    //   alignTolerance: any,
    //   changeGroupText: any,
    //   changeStyle: any,
    //   check: any,
    //   addText: any,
    // } 
    = {
    canvas: null,
    rect: null,
    isAdmin: false,
    backgroundImageUrls: [],
    modelImageIds: [],
    lines: {
        centerX: null,
        centerY: null,
        top: null,
        left: null,
        right: null,
        bottom: null,
    },
    setValue: null,
    setText: null,
    highlight: null,
    lockedItemIds: [],
    get: elm => {
        const top = parseInt(elm.get("top")),
            left = parseInt(elm.get("left")),
            right = parseInt(elm.get("left") + elm.get("width") * elm.scaleX),
            bottom = parseInt(elm.get("top") + elm.get("height") * elm.scaleY)
        const centerX = Math.floor((left + right) / 2),
            centerY = Math.floor((top + bottom) / 2)

        return { top, left, right, bottom, centerX, centerY }
    },
    init: (setValue, setText, cb) => {
        fabric.Object.prototype.objectCaching = false;
        Def.setValue = setValue
        Def.setText = setText
        Def.canvas = new fabric.Canvas("customize", {
            height: 480,
            width: 650,
            backgroundColor: "white",
            preserveObjectStacking: true,
        })
        window.addEventListener('keydown', (event) => {
            let elm = Def.canvas.getActiveObject(), dis = 1;
            if (!elm) return;
            switch (event.key) {
                case 'ArrowUp':
                    elm.set('top', elm.get('top') - dis)
                    break
                case 'ArrowRight':
                    elm.set('left', elm.get('left') + dis)
                    break
                case 'ArrowDown':
                    elm.set('top', elm.get('top') + dis)
                    break
                case 'ArrowLeft':
                    elm.set('left', elm.get('left') - dis)
                    break
            }
            Def.canvas.renderAll()
        })

        Def.canvas.on("object:moving", Def.events.objectMoving)
        Def.canvas.on("object:moving", Def.events.deleteHighlight)
        Def.canvas.on("object:moving", Def.events.handleGuideLines)
        Def.canvas.on("object:moved", Def.clearLines)
        Def.canvas.on("object:moved", Def.events.removeGuideLines)
        Def.canvas.on('selection:created', Def.events.objectSelected)
        Def.canvas.on('selection:updated', Def.events.objectSelected)
        Def.canvas.on('selection:cleared', Def.events.selectionCleared)
        Def.canvas.on('mouse:over', Def.events.highlight)
        Def.canvas.on('mouse:out', Def.events.deleteHighlight)
        const handleBoundary = (e: any) => {
            if (e.selected[0] && e.selected[0].id && e.selected[0].id.startsWith('boundary')) {
                return;
            }
            e.selected.forEach(d => MakeBoundaryVisible(d.boundaryId));
        }

        Def.canvas.on('selection:created', handleBoundary);
        Def.canvas.on('selection:updated', handleBoundary);
        Def.canvas.on('before:selection:cleared', () => MakeBoundaryVisible(''));

        Def.canvas.on('mouse:down', function (opt) {
            var evt = opt.e;
            // if (this.isHoldingAlt === true) {
            if (!Def.canvas.getActiveObject()) {
                this.isDragging = true;
                this.selection = false;
                this.lastPosX = evt.clientX;
                this.lastPosY = evt.clientY;
            }
        });
        Def.canvas.on('mouse:move', function (opt) {
            if (this.isDragging) {
                var e = opt.e;
                var vpt = this.viewportTransform;

                if (!e.clientX || !e.clientY) {
                    if (e && e.touches && e.touches.length > 0) {
                        e.clientX = e.touches[0].clientX;
                        e.clientY = e.touches[0].clientY;
                    }
                }
                if (typeof this.lastPosX !== 'number') {
                    this.lastPosX = e.clientX;
                }
                if (typeof this.lastPosY !== 'number') {
                    this.lastPosY = e.clientY;
                }

                vpt[4] += e.clientX - this.lastPosX;
                vpt[5] += e.clientY - this.lastPosY;
                this.requestRenderAll();
                this.lastPosX = e.clientX;
                this.lastPosY = e.clientY;
            }
        });
        Def.canvas.on('mouse:up', function (opt) {
            // on mouse up we want to recalculate new interaction
            // for all objects, so we call setViewportTransform
            this.setViewportTransform(this.viewportTransform);
            this.isDragging = false;
            this.selection = true;
        });

        Def.canvas.on('object:selected', function () {
            this.pausePanning = true;
        });

        Def.canvas.on('selection:cleared', function () {
            this.pausePanning = false;
        });

        Def.canvas.on('touch:drag', function (e) {
            if (!this.lastX) {
                this.lastX = 0;
            }
            if (!this.lastY) {
                this.lastY = 0;
            }
            if (this.pausePanning == false && undefined != e.e.layerX && undefined != e.e.layerY) {
                let currentX = e.e.layerX;
                let currentY = e.e.layerY;
                let xChange = currentX - this.lastX;
                let yChange = currentY - this.lastY;

                if ((Math.abs(currentX - this.lastX) <= 50) && (Math.abs(currentY - this.lastY) <= 50)) {
                    var delta = new fabric.Point(xChange, yChange);
                    Def.canvas.relativePan(delta);
                }

                this.lastX = e.e.layerX;
                this.lastY = e.e.layerY;
            }
        });

        cb();
    },
    activeMaterialIndex: 0,
    events: {
        objectMoving: e => {
            // const obj = e.target
            // const curPos = Def.get(obj)

            // const objects = Def.canvas.getObjects()
            // const matches = {
            //   top: false,
            //   left: false,
            //   right: false,
            //   bottom: false,
            //   centerX: false,
            //   centerY: false,
            // }
            // objects.forEach(elm => {
            //   if (elm === obj || elm.get("type") === "line") return
            //   const objPos = Def.get(elm)
            //   let y = Def.check(curPos.top, [
            //     objPos.top,
            //     objPos.bottom,
            //     objPos.centerY,
            //   ])
            //   if (y) matches.top = y
            //   let x = Def.check(curPos.left, [
            //     objPos.left,
            //     objPos.right,
            //     objPos.centerX,
            //   ])
            //   if (x) matches.left = x

            //   y = Def.check(curPos.bottom, [
            //     objPos.top,
            //     objPos.bottom,
            //     objPos.centerY,
            //   ])
            //   if (y) matches.bottom = y
            //   x = Def.check(curPos.right, [objPos.left, objPos.right, objPos.centerX])
            //   if (x) matches.right = x
            //   y = Def.check(curPos.centerY, [
            //     objPos.top,
            //     objPos.bottom,
            //     objPos.centerY,
            //   ])
            //   if (y) matches.centerY = y
            //   x = Def.check(curPos.centerX, [
            //     objPos.left,
            //     objPos.right,
            //     objPos.centerX,
            //   ])
            //   if (x) matches.centerX = x
            // })

            // for (const key in Def.lines) {
            //   const line = Def.lines[key]
            //   if (line) {
            //     Def.canvas.remove(line)
            //     Def.lines[key] = null
            //   }
            //   if (matches[key] !== false) Def.drawLine(key, matches[key])
            // }

            // Def.canvas.renderAll()
        },
        handleGuideLines: e => {
            const guideLineGroup = Def.canvas.getObjects().find(d => d.id && d.id.startsWith(`material-guideline-group`))
            if (!guideLineGroup) {
                return
            }

            guideLineGroup.opacity = 1
            const elm = e.target;
            const verticalLine = guideLineGroup._objects.find(d => d.id && d.id.startsWith('material-vertical-guideline'))
            const horizontalLine = guideLineGroup._objects.find(d => d.id && d.id.startsWith('material-horizontal-guideline'))

            if (elm.text) {
                if (Math.abs((elm.left) - (guideLineGroup.left + guideLineGroup.width * guideLineGroup.scaleX * .5)) < 4) {
                    verticalLine.opacity = 1
                    elm.left = guideLineGroup.left + (guideLineGroup.width * guideLineGroup.scaleX / 2)
                } else {
                    verticalLine.opacity = 0
                }

                if (Math.abs((elm.top) - (guideLineGroup.top + (guideLineGroup.height * guideLineGroup.scaleY) / 2)) < 4) {
                    horizontalLine.opacity = 1
                    elm.top = guideLineGroup.top + (guideLineGroup.height * guideLineGroup.scaleY / 2)
                } else {
                    horizontalLine.opacity = 0
                }
            } else {
                if (Math.abs((elm.left + elm.width * elm.scaleX / 2) - (guideLineGroup.left + guideLineGroup.width * guideLineGroup.scaleX * .5)) < 4) {
                    verticalLine.opacity = 1
                    elm.left = guideLineGroup.left + (guideLineGroup.width * guideLineGroup.scaleX / 2) - elm.width * elm.scaleX / 2
                } else {
                    verticalLine.opacity = 0
                }

                if (Math.abs((elm.top + elm.height * elm.scaleY / 2) - (guideLineGroup.top + (guideLineGroup.height * guideLineGroup.scaleY) / 2)) < 4) {
                    horizontalLine.opacity = 1
                    elm.top = guideLineGroup.top + (guideLineGroup.height * guideLineGroup.scaleY / 2) - elm.height * elm.scaleY / 2
                } else {
                    horizontalLine.opacity = 0
                }
            }
        },
        removeGuideLines: e => {
            Def.canvas.getObjects()
                .filter(d => d.id && d.id.startsWith('material-guideline-group'))
                .forEach(d => d._objects.forEach(dd => {
                    if (!dd.id) {
                        return
                    }
                    if (dd.id.includes('vertical') || dd.id.includes('horizontal')) {
                        dd.opacity = 0
                    }
                }))
            // if (Def.isAdmin) {
            //   Def.canvas.getObjects()
            //     .filter(d => d.id && d.id.startsWith('material-guideline-group'))
            //     .forEach(d => d._objects.forEach(dd => dd.id && (dd.id.includes('vertical') || dd.id.includes('horizontal')) && dd.opacity = 0))
            // } else {
            //   Def.canvas.getObjects()
            //     .filter(d => d.id && d.id.startsWith('material-guideline-group'))
            //     .forEach(d => d.opacity = 0)
            // }
        },
        objectSelected: e => {
            if (e.target.id && e.target.id.includes('image')) {
                return;
            }

            if (e.target.id && !e.target.id.includes('text')) {
                return;
            }

            const elm = e.target;
            Def.events.deleteHighlight(e)
            Def.setValue({
                fontFamily: elm.fontFamily,
                fill: elm.fill,
                lineSpace: elm.lineSpace,
                fontWeight: elm.fontWeight,
                fontStyle: elm.fontStyle,
                underline: elm.underline,
                charSpacing: elm.charSpacing ? elm.charSpacing : 0,
                visibility: (elm.text && elm.text.length),
                angle: elm.circleAngle,
            })
            Def.setText(elm.text ? elm.text : '')
        },
        selectionCleared: e => {
            Def.setValue({ visibility: false })
        },
        highlight: e => {
            const elm = e.target;
            if (!elm || elm.rect || (elm == Def.canvas.getActiveObject())) return

            if (!elm) return
            let { left, top, width, height, angle, scaleX, scaleY } = elm

            if (e.target.id && e.target.id.includes('zuraas')) return;

            if (elm.text) {
                if (!angle) top = (top - (height * scaleY / 2)), left = (left - (width * scaleX / 2))
                else {
                    const dis = Math.sqrt(width * width * scaleX * scaleX + height * height * scaleY * scaleY) / 2
                    const xx = Math.atan(height / width) + (angle * Math.PI / 180.0)
                    left = left - (dis * Math.cos(xx))
                    top = top - (dis * Math.sin(xx))
                }
            }
            const rect = new fabric.Rect({
                width, height, scaleX, scaleY, angle,
                top: top, left: left,
                fill: 'rgba(0,0,0,0)',
                strokeDashArray: [8, 4],
                strokeWidth: 2, stroke: 'rgba(100,200,200,1)',
                strokeUniform: true,
                rect: true,
            })
            rect.perPixelTargetFind = true;
            Def.highlight = rect
            Def.canvas.add(rect)
            // Def.canvas.sendToBack(rect)
            // Def.canvas.bringForward(elm)
            Def.canvas.renderAll()
        },
        deleteHighlight: e => {
            if (!Def.highlight) return
            Def.canvas.remove(Def.highlight)
            Def.canvas.renderAll()
            Def.highlight = null;
        }
    },
    drawLine: (side, pos) => {
        // let ln = null
        // switch (side) {
        //   case "top":
        //   case "bottom":
        //   case "centerY":
        //     ln = new fabric.Line([Def.canvas.get("width"), 0, 0, 0], {
        //       left: 0,
        //       top: pos,
        //       stroke: "rgb(178, 207, 255)",
        //     })
        //     break
        //   case "left":
        //   case "right":
        //   case "centerX":
        //     ln = new fabric.Line([0, Def.canvas.get("height"), 0, 0], {
        //       left: pos,
        //       top: 0,
        //       stroke: "rgb(178, 207, 255)",
        //     })
        //     break
        // }
        // if (ln) Def.canvas.add(ln).renderAll(), (Def.lines[side] = ln)
    },
    clearLines: () => {
        for (const key in Def.lines) {
            const line = Def.lines[key]
            if (line) {
                Def.canvas.remove(line)
                Def.lines[key] = null
            }
        }
    },
    alignTolerance: 6,
    check: (elm, arr) => {
        for (const i in arr) {
            if (Math.max(elm, arr[i]) - Math.min(elm, arr[i]) <= Def.alignTolerance)
                return arr[i]
        }
        return false
    },
    changeGroupText: (text) => {
        if (!Def.canvas) return
        let activeObject = Def.canvas.getActiveObject();
        if (!activeObject) return
        changeText(activeObject, text)
        Def.canvas.renderAll()
    },
    addText: (text, values, id, color) => {
        let curvedText = CurvedText(text ? text : `Change this text`, {
            fontSize: values.fontSize ? values.fontSize : 20,
            charSpacing: values.charSpacing ? values.charSpacing : 0,
            fill: values.fill
                ? values.fill
                : color
                    ? color
                    : 'black',
            id, fontFamily: 'default'
        })

        curvedText.id = id;
        setZIndex(curvedText, 3, Def.canvas);

        Def.canvas.add(curvedText)
        Def.canvas.renderAll()
    },
    changeStyle: async (type, value) => {
        let activeObject = Def.canvas.getActiveObject();
        if (!activeObject) return
        await changeTextStyle(activeObject, type, value)
        if (type == 5) Def.canvas.remove(activeObject)
        if (type == 10) changeAlignment(activeObject, value)
        Def.canvas.renderAll()
    },
    changeStyleById: async (id, type, value) => {
        let activeObject = Def.canvas.getObjects().find(obj => obj.id === id);
        if (!activeObject) return
        await changeTextStyle(activeObject, type, value)
        if (type == 5) Def.canvas.remove(activeObject)
        if (type == 10) changeAlignment(activeObject, value)
        Def.canvas.renderAll()
    },
}

export const AddImages = images => {
    images.forEach(url => {
        fabric.Image.fromURL(url, img => {
            img.scaleToWidth(100)
            Def.canvas.add(img).renderAll()
        }, { crossOrigin: 'anonymous' })
    })
}

export const ToJson = (canvas?: any) =>
    (canvas || Def.canvas).toJSON(['lineSpace', 'text', 'circleAngle', 'id', 'opacity', 'boundaryId', 'perPixelTargetFind', 'crossOrigin', 'fontFileName', 'fontUrl']);

export const ToJsonUsedOnly = async () => {
    return new Promise(res => {
        const oldJSON = ToJson()

        var tmpCanvas = new fabric.Canvas('canvas');
        tmpCanvas.loadFromJSON(ToJson(), () => {
            tmpCanvas.getObjects()
                .forEach(obj => {
                    if (obj.opacity == 0) {
                        tmpCanvas.remove(obj).renderAll()
                    }
                });
            res(ToJson(tmpCanvas))
        })
    })
}

export const LoadFromJson = (json, cb) => {
    Def.canvas.loadFromJSON(JSON.parse(JSON.stringify(json)), async function () {
        cb();
        Def.canvas.getObjects()
            .filter(d => d.id && d.id.startsWith('material-guideline-group'))
            .forEach((d, index) => {
                if (index === 0) {
                    d.id = 'material-guideline-group-0';
                } else if (d.id !== 'material-guideline-group-0') {
                    Def.canvas.remove(d).renderAll()
                }
            })


        try {
            await Promise.all(Def.canvas.getObjects().map(async (obj) => {
                obj.text = getText(obj)
                const angle = obj.circleAngle;

                if (obj.text) {
                    copyStyle(obj, obj._objects[0])
                    await changeTextStyle(obj, 4, { fontFamily: obj.fontFamily })
                    if (!obj.circleAngle) changeAlignment(obj, obj.alignment ? obj.alignment : 'center'), obj.set('multipleLine', true)
                    if (obj.text.split('\n').length > 1) await obj.set('multipleLine', true)
                    else await obj.set('multipleLine', false)
                }
                obj.set('circleAngle', angle)
            }))
        } catch (e) {

        }

        Def.canvas.getObjects().forEach(obj => {

            if (obj.id && obj.id.startsWith('boundary')) {
                obj.selectable = false;
                obj.evented = false;
                obj.opacity = 0;
            }

            if (obj.stroke) {
                obj.strokeUniform = true;
            }
        })

        Def.canvas.renderAll();
    }, function (o, object) {
    })
}

export const CreateGroup = (id: string) => {
    const group = new fabric.Group([], {
        top: 0, left: 0,
        originX: 'center',
        originY: 'center'
    });
    group.id = id;
    Def.canvas.add(group).renderAll();
    return group;
}

export const ChangeImageUrlById = (id: string, url: string) => {
    let obj = Def.canvas.getObjects().find(obj => obj.id === id);
    if (obj) {
        obj.setSrc(url);
        Def.canvas.renderAll();
    }
}

interface IImageOptions extends fabric.IImageOptions {
    id: string;
}

export const ChangeBackgroundImage = (pageId: string, index: number, url: string, visible: boolean) => {
    const bgId = `${pageId}-background-image-${index}`;
    const options: IImageOptions = { id: bgId };

    fabric.Image.fromURL(url, img => {
        img.scaleToWidth(100);
        // remove first
        let obj = Def.canvas.getObjects().find(obj => obj.id === bgId);
        Def.canvas.remove(obj);

        img.opacity = visible ? 1 : 0;
        img.selectable = visible;
        img.evented = visible;

        // add it then
        img.id = bgId;
        Def.canvas.add(img).renderAll();
    }, { ...options, crossOrigin: 'anonymous' });
}

export const AddModelImageToGroup = (groupId, modelId, url) => {
    const options: IImageOptions = { id: modelId };

    // let group = Def.canvas.getObjects().find(obj => obj.id === groupId);

    fabric.Image.fromURL(url, img => {
        img.scaleToWidth(100);

        img.id = modelId;
        // img.opacity = 0;
        // img.selectable = false;
        // img.evented = false;

        // group.addWithUpdate(img);
        Def.canvas.add(img).renderAll();
    }, options);
}

export const AddModelImage = (id: string, url: string, visible: boolean, color: string) => {
    // remove it first
    let obj = Def.canvas.getObjects().find(obj => obj.id === id);
    if (obj) {
        Def.canvas.remove(obj);
    }

    const options: IImageOptions = { id };

    fabric.Object.prototype.objectCaching = false

    fabric.loadSVGFromURL(url, function (objects) {
        var obj = fabric.util.groupSVGElements(objects, options);
        obj.id = id;
        obj.opacity = visible ? 1 : 0;
        obj.selectable = visible;
        obj.evented = visible;

        Def.canvas.add(obj).renderAll();

        if (color) {
            obj.fill = color;

            if (obj._objects) {
                for (var i = 0; i < obj._objects.length; i++) {
                    obj._objects[i].fill = color;
                }
            }

            Def.canvas.renderAll();
        }

    }, null, { id, crossOrigin: 'anonymous' })

    Def.modelImageIds.push(id);
}

export const AddUserImage = (url: string, id: string, boundaryId: string, cb) => {
    const options: IImageOptions = { id };

    const boundaryBox = Def.canvas.getObjects().find(d => d.id === boundaryId);

    fabric.Image.fromURL(url, img => {
        img.id = id;
        img.boundaryId = boundaryId;

        img.left = boundaryBox.left;
        img.top = boundaryBox.top;
        const width = boundaryBox.width * boundaryBox.scaleX;
        img.scaleToWidth(boundaryBox ? width / 2 : 200);


        Def.canvas.add(img).renderAll();
        cb(img, boundaryBox);
    }, { ...options, crossOrigin: 'anonymous' });
}

export const RemoveUserImage = (id: string) => {
    const img = Def.canvas.getObjects().find(d => d.id === id);
    if (img) {
        Def.canvas.remove(img).renderAll();
    }
}

export const RemoveObjectById = (id: string) => {
    let obj = Def.canvas.getObjects().find(obj => obj.id === id);
    if (obj) {
        Def.canvas.remove(obj);
    }
}

export const RemoveModelImage = (id: string) => {
    let obj = Def.canvas.getObjects().find(obj => obj.id === id);
    if (obj) {
        Def.canvas.remove(obj);
    }
}

export const MakePageItemsVisible = (pageId: string, isAdmin: boolean) => {
    Deselect();
    // hide all items
    Def.canvas.getObjects()
        .forEach(obj => {
            obj.opacity = 0;
            obj.selectable = false;
            obj.evented = false;
        })

    // show page items
    Def.canvas.getObjects()
        .filter(obj => obj.id && obj.id.includes(pageId))
        .forEach(obj => {
            const isLocked = Def.lockedItemIds.findIndex(lockedItemId => lockedItemId === obj.id) !== -1;
            obj.opacity = 1;
            obj.selectable = !isLocked;
            obj.evented = !isLocked;
        })

    Def.canvas.renderAll();
}

export const MakeModelItemsVisible = (modelId: string, isAdmin: boolean) => {
    Deselect();

    const allObjects = Def.canvas.getObjects();
    const allModelObjects = allObjects.filter(obj => obj.id && obj.id.includes('model'));
    allModelObjects.forEach(obj => {
        obj.opacity = 0;
        obj.selectable = false;
        obj.evented = false;
    });

    const selectedModelObjects = allObjects.filter(obj => obj.id.includes(`${modelId}`));
    selectedModelObjects.forEach(obj => {
        const isLocked = Def.lockedItemIds.findIndex(lockedItemId => lockedItemId === obj.id) !== -1;
        obj.selectable = !isLocked;
        obj.evented = !isLocked;
        obj.opacity = 1;
        if (obj.id && obj.id.startsWith('material-guideline-group')) {
            obj._objects
                .filter(d => d.id && d.id === "material-guideline-box-0")
                .forEach(d => {
                    if (!isAdmin) {
                        d.opacity = 0;
                    }
                })
        }
    });

    Def.canvas.renderAll();
}

export const Deselect = () => {
    Def.canvas.discardActiveObject().renderAll();
}

export const RemoveModel = (modelId: string) => {
    const modelObjects = Def.canvas.getObjects().filter(obj => obj.id.includes(modelId));
    modelObjects.forEach(obj => {
        Def.canvas.remove(obj);
    })
}

export const GetObjectById = (id: string) => {
    return Def.canvas.getObjects().find(obj => obj.id === id);
}

export const ChangeModelColor = (modelId: string, color: any) => {
    const modelObjects = Def.canvas.getObjects().filter(obj => obj.id.includes(modelId));
    modelObjects.forEach(obj => {
        if (obj.id.includes('image')) {
            obj.crossOrigin = 'anonymous';
            // const filter = new fabric.Image.filters.BlendColor({
            //   color: rgbaToString(color),
            //   mode: 'tint',
            //   // alpha: color.a
            // });
            // obj.filters && (obj.filters[0] = filter);
            // obj.applyFilters && obj.applyFilters();
            obj.fill = rgbToString(color);

            if (obj._objects) {
                for (var i = 0; i < obj._objects.length; i++) {
                    obj._objects[i].fill = rgbToString(color);
                }
            }

            Def.canvas.renderAll();
        } else if (obj.id.includes('text')) {
            Def.changeStyleById(obj.id, 6, rgbToString(color));
            Def.canvas.renderAll();
        }
    })
}

export const ChangeTextColor = (id, color) => {
    Def.changeStyleById(id, 6, rgbToString(color));
    Def.canvas.renderAll();
}

export const ResetModelColor = (modelId: string) => {
    const modelObjects = Def.canvas.getObjects().filter(obj => obj.id.includes(modelId));
    modelObjects.forEach(obj => {
        if (obj.id.includes('image')) {
            obj.crossOrigin = 'anonymous';
            obj.fill = "#000000";

            if (obj._objects) {
                for (var i = 0; i < obj._objects.length; i++) {
                    obj._objects[i].fill = "#000000";
                }
            }
            // obj.filters = [];
            // obj.applyFilters();
            Def.canvas.renderAll();
        } else if (obj.id.includes('text')) {
            Def.changeStyleById(obj.id, 6, 'black');
            Def.canvas.renderAll();
        }
    })
}

export const rgbaToString = (color: any) => `rgba(${color.r},${color.g},${color.b},${color.a})`;

export const rgbToString = (color: any) => `rgb(${color.r},${color.g},${color.b})`

export const MakeModelImageVisible = (modelIds: string[], id: string, isAdmin) => {
    modelIds.forEach(d => {
        let obj = Def.canvas.getObjects().find(obj => obj.id === d);

        if (obj) {
            obj.opacity = 0;
            obj.selectable = false;
            obj.evented = false;
        }
    })

    let obj = Def.canvas.getObjects().find(obj => obj.id === id);
    const isLocked = Def.lockedItemIds.findIndex(d => d === id) !== -1;
    if (obj) {
        obj.opacity = 1;
        obj.selectable = !isLocked || isAdmin;
        obj.evented = !isLocked || isAdmin;
    }

    Def.canvas.discardActiveObject().renderAll();
}

export const MakeBgVisible = (pageId: string, index: number = 0, isAdmin: boolean) => {
    Def.activeMaterialIndex = 0;
    Def.canvas.getObjects()
        .filter(obj => obj.id.includes('background-image'))
        .forEach(obj => {
            obj.opacity = 0;
            obj.selectable = false;
            obj.evented = false;
        });

    const id = `${pageId}-background-image-${index}`;
    let obj = Def.canvas.getObjects().find(obj => obj.id === id);
    if (obj) {
        const isLocked = Def.lockedItemIds.findIndex(d => d === id) !== -1;
        obj.opacity = 1;
        obj.selectable = !isLocked;
        obj.evented = !isLocked;
    }

    Def.canvas.getObjects()
        .filter(obj => obj.id && obj.id.startsWith(`material-guideline-group`))
        .forEach(obj => {
            obj.opacity = 0;
            obj.selectable = false;
            obj.evented = false;
        })
    const guideLineGroupId = `material-guideline-group-0`
    const guideLineGroup = Def.canvas.getObjects().find(d => d.id === guideLineGroupId)

    if (guideLineGroup) {
        guideLineGroup.opacity = 1;
        guideLineGroup.selectable = true;
        guideLineGroup.evented = true;

        // const verticalId = `material-vertical-guideline-0`
        // const horizontalId = `material-horizontal-guideline-0`
        // const boxId = `material-guideline-box-0`


        if (!isAdmin) {
            guideLineGroup._objects
                // .filter(d => d.id === verticalId || d.id === horizontalId || d.id === boxId)
                .forEach(obj => {
                    obj.opacity = 0;
                    obj.selectable = false;
                    obj.evented = false;
                })
        }
    }

    Def.canvas.discardActiveObject().renderAll();
}

export const CenterImage = (id: string) => {
    let obj = Def.canvas.getObjects().find(obj => obj.id === id);
    if (!obj) {
        return;
    }
    obj.left = CANVAS_WIDTH / 2 - obj.width * obj.scaleX / 2;
    obj.top = CANVAS_HEIGHT / 2 - obj.height * obj.scaleY / 2;
    Def.canvas.renderAll();
}

export const PositionAndScaleBg = (pageId: string, index: number) => {
    let firstBgObj = Def.canvas.getObjects().find(obj => obj.id === `${pageId}-background-image-0`);
    if (firstBgObj == null) {
        return;
    }

    const { left, top, scaleX, scaleY, angle } = firstBgObj;
    let obj = Def.canvas.getObjects().find(obj => obj.id === `${pageId}-background-image-${index}`);
    if (obj) {
        obj.left = left;
        obj.top = top;
        obj.scaleX = scaleX;
        obj.scaleY = scaleY;
        obj.angle = angle;
    }

    Def.canvas.renderAll();
}

export const ToggleGuideLineBox = (index: number = 0) => {
    const guideLineId = `material-guideline-group-0`
    const itemObj = Def.canvas.getObjects().find(obj => obj.id === guideLineId)

    if (itemObj) {
        Def.canvas.remove(itemObj).renderAll()
        return
    }

    const width = 600
    const height = 400
    const left = 0
    const top = 0

    const boundaryBox = new fabric.Rect({
        fill: "transparent",
        width: 600,
        height: 400,
        stroke: "blue",
        strokeDashArray: [5, 5],
        strokeWidth: 4,
        strokeUniform: true,
        id: guideLineId,
        left: 0,
        top: 0,
    });


    const verticalGuideLine = new fabric.Line([
        left + (width / 2), top - 5,
        left + (width / 2), top + height + 5
    ], {
        stroke: 'green',
        strokeWidth: 2,
        id: `material-vertical-guideline-0`,
        strokeDashArray: [5, 5],
    })

    const horizontalGuideLine = new fabric.Line([
        left - 5, top + (height / 2),
        left + width + 5, top + (height / 2)
    ], {
        stroke: 'green',
        strokeWidth: 2,
        id: `material-horizontal-guideline-0`,
        strokeDashArray: [5, 5],
    })

    verticalGuideLine.id = `material-vertical-guideline-0`
    verticalGuideLine.evented = false
    horizontalGuideLine.id = `material-horizontal-guideline-0`
    horizontalGuideLine.evented = false
    verticalGuideLine.selectable = false
    horizontalGuideLine.selectable = false
    // Def.canvas.add(verticalGuideLine)
    // Def.canvas.add(horizontalGuideLine)


    boundaryBox.id = `material-guideline-box-0`;
    const group = new fabric.Group([boundaryBox, verticalGuideLine, horizontalGuideLine])
    group.perPixelTargetFind = true;
    group.id = guideLineId
    group.setControlsVisibility({ mtr: false })
    Def.canvas.add(group).renderAll();
}

export const AddBoundary = (boundaryId: string, itemId: string) => {
    const itemObj = Def.canvas.getObjects().find(obj => obj.id === itemId);
    if (!itemObj) {
        return;
    }

    itemObj.boundaryId = boundaryId;

    const boundaryBox = new fabric.Rect({
        fill: "transparent",
        width: 600,
        height: 400,
        stroke: "red",
        strokeDashArray: [5, 5],
        strokeWidth: 1,
        strokeUniform: true,
        id: boundaryId,
        left: 0,
        top: 0,
    });

    boundaryBox.id = boundaryId;
    boundaryBox.perPixelTargetFind = true;
    Def.canvas.add(boundaryBox).renderAll();
}

export const USER_IMAGE_BOUNDARY_ID = 'zurag-zuraas';
export const AddUserImageBoundary = (id) => {
    const DEFAULT_WIDTH = 600, DEFAULT_HEIGHT = 400;
    let boundaryBox = Def.canvas.getObjects().find(obj => obj.id === id)
    // if (!boundaryBox) {
    boundaryBox = new fabric.Rect({
        fill: "rgb(217, 217, 217)",
        width: DEFAULT_WIDTH,
        height: DEFAULT_HEIGHT,
        id: USER_IMAGE_BOUNDARY_ID,
        left: 0,
        top: 0,
    });

    // boundaryBox.id = USER_IMAGE_BOUNDARY_ID;
    // Def.canvas.add(boundaryBox).renderAll();
    // }

    fabric.loadSVGFromURL('https://firebasestorage.googleapis.com/v0/b/monzaya-1f761.appspot.com/o/images%2Fimages.svg?alt=media&token=7be4ffd0-f960-442f-a41d-12d2b6432725', function (objects, options) {
        var svg = fabric.util.groupSVGElements(objects, options);
        svg.left = DEFAULT_WIDTH / 2 - 32;
        svg.top = DEFAULT_HEIGHT / 2 - 32;
        svg.scaleToWidth(64);
        svg.selectable = false;

        const group = new fabric.Group([boundaryBox, svg]);
        group.evented = true;
        group.id = id;
        Def.canvas.add(group).renderAll();

        // Def.canvas.add(svg);
        // Def.canvas.renderAll();
    });




    //   fabric.loadSVGFromURL('https://firebasestorage.googleapis.com/v0/b/monzaya-1f761.appspot.com/o/images%2Fimages.svg?alt=media&token=7be4ffd0-f960-442f-a41d-12d2b6432725', function(objects, options) {
    //    var svg = fabric.util.groupSVGElements(objects, options);
    //    svg.left = 50;
    //    svg.top = 50;
    //    svg.scaleToWidth(100);
    //    console.log(svg);
    //    Def.canvas.add(svg);
    //    Def.canvas.renderAll();
    // }, { crossOrigin: 'anonymous' });

    // fabric.Image.fromURL('https://firebasestorage.googleapis.com/v0/b/monzaya-1f761.appspot.com/o/images%2Fimages.svg?alt=media&token=7be4ffd0-f960-442f-a41d-12d2b6432725', img => {
    //   img.scaleToWidth(64)
    //   console.log(img);
    //   Def.canvas.add(img).renderAll()
    // }, { crossOrigin: 'anonymous' });
}

export const RemoveUserImageBoundary = (id: string) => {
    let boundaryBox = Def.canvas.getObjects().find(obj => obj.id === id)
    if (boundaryBox) {
        Def.canvas.remove(boundaryBox).renderAll();
    }
}

export const RemoveBoundary = (boundaryId: string, itemId: string) => {
    const boundaryBox = Def.canvas.getObjects().find(obj => obj.id === boundaryId);
    if (boundaryBox) {
        const itemObj = Def.canvas.getObjects().find(obj => obj.id === itemId);
        if (itemObj) {
            itemObj.clipPath = null;
        }
        Def.canvas.remove(boundaryBox).renderAll();
    }
}

export const MakeImageBoundaryVisible = (id: string, visible: boolean) => {
    Def.canvas.getObjects()
        .filter(d => d.id.includes('zuraas'))
        .forEach(obj => {
            obj.opacity = 0;
            obj.selectable = false;
            obj.evented = false;
        });

    let obj = Def.canvas.getObjects().find(d => d.id === id);
    if (obj) {
        const isLocked = Def.lockedItemIds.findIndex(id => obj.id === id) !== -1;

        obj.opacity = visible ? 1 : 0;
        obj.selectable = !isLocked;
        obj.evented = true;

        Def.canvas.renderAll();
    }
}

export const MakeUserImageVisible = () => {
    Def.canvas.getObjects()
        .filter(obj => obj.id.startsWith('user-image'))
        .forEach(img => {
            img.opacity = 1;
            img.selectable = true;
            img.evented = true;
        });

    Def.canvas.renderAll();
}

export const MakeBoundaryVisible = (boundaryId: string) => {
    Def.canvas.getObjects()
        .forEach(obj => {
            if (obj.id && obj.id.includes('boundary')) {
                obj.opacity = 0;
                obj.selectable = false;
                obj.evented = false;
            }
        });

    const boundaryBox = Def.canvas.getObjects().find(obj => obj.id === boundaryId);
    if (boundaryBox) {
        boundaryBox.opacity = 1;
        boundaryBox.selectable = true && Def.isAdmin;
        boundaryBox.evented = true && Def.isAdmin;
    }

    Def.canvas.renderAll();
}


export const LockLockedItems = async (lockedItemIds: string[]) => {
    if (Def.canvas == null) {
        return;
    }
    const allObjects = Def.canvas.getObjects();

    allObjects.forEach(obj => {
        if (!obj.id) {
            return;
        }

        const isLocked = lockedItemIds.findIndex(id => obj.id === id) !== -1
            // || obj.id.startsWith('background')
            || obj.id.startsWith('boundary');

        obj.selectable = !isLocked;
        obj.evented = !isLocked;
    });

    Def.lockedItemIds = lockedItemIds;

    Def.canvas.renderAll();
}

export const UnlockAllItems = () => {
    if (Def.canvas == null) {
        return;
    }
    const allObjects = Def.canvas.getObjects();
    allObjects.forEach(obj => {
        if (obj.opacity == 0) {
            return;
        }
        obj.selectable = true;
        obj.evented = true;
    });

    Def.canvas.renderAll();
}

export const HideCurrentMaterial = () => {
    const ret = Def.canvas.getObjects()
        .filter(obj => obj.id.includes('background-image') && obj.opacity == 1);

    if (ret.length === 0) {
        return null;
    }
    ret[0].opacity = 0;
    Def.canvas.renderAll();
    return ret[0].id;
}

export const ToggleUserImageBoundaryBoxVisibility = (visible: boolean) => {
    const obj = Def.canvas.getObjects()
        .find(d => d.id.startsWith('zurag-zuraas'));
    if (obj) {
        obj.opacity = visible ? 1 : 0;
        Def.canvas.renderAll();
        return obj.id;
    }
    return null;
}

export const ToggleUserImagesVisibility = (visible: boolean) => {
    Def.canvas.getObjects()
        .filter(obj => obj.id.includes('user-image'))
        .forEach(img => {
            img.opacity = visible;
        });
    Def.canvas.renderAll();
}

export const ToSVG = () => {
    let w = window.open('');
    w.document.write(Def.canvas.toSVG());
    const url = 'data:image/svg+xml;utf8,' + encodeURIComponent(Def.canvas.toSVG());
    var downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = "newesttree.svg";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

export const ToImage = () => {
    const url = Def.canvas.toDataURL({
        format: 'jpg',
        quality: 0.8
    });
    // const link = document.createElement('a');
    // link.download = 'image.jpg';
    // link.href = url;
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    return url;
}

fabric.Image.prototype.getSvgSrc = function () {
    return this.toDataURLforSVG();
};

fabric.Image.prototype.toDataURLforSVG = function (options) {
    var el = fabric.util.createCanvasElement();
    el.width = this._element.naturalWidth || this._element.width;
    el.height = this._element.naturalHeight || this._element.height;
    el.getContext("2d").drawImage(this._element, 0, 0);
    var data = el.toDataURL(options);
    return this.opacity == 1 ? data : null;
};

export const DownloadSVG = async (name) => {
    Def.canvas.getObjects().forEach(obj => {
        if (obj.opacity == 0) {
            Def.canvas.remove(obj);
        }
    });

    // changin font-family property for other programs like AI, PS
    Def.canvas.getObjects().map(obj => {
        if (obj.text) {
            let ind = 0;

            obj.text.split('').forEach(c => {
                const item = obj.item(ind);
                if (item) {
                    item.oldFontFamily = item.fontFamily;
                    if (obj.fontFileName) {
                        item.set("fontFamily", obj.fontFileName.split('.')[0])
                    }
                }
                ind++
            })

            obj.oldFontFamily = obj.fontFamily;
            if (obj.fontFileName) {
                obj.set('fontFamily', obj.fontFileName.split('.')[0]);
            }
        }
    })


    // _.chain(Def.canvas.getObjects())
    //   .filter(d => d.text)
    //   .forEach(d => {
    //     console.log(d);
    //     d.fontFamily = d.fontFileName
    //   })

    // Def.canvas.renderAll();

    var svgData = Def.canvas.toSVG(); //.replaceAll('&', '&amp;');
    var svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
    var svgUrl = URL.createObjectURL(svgBlob);
    var downloadLink = document.createElement("a");
    downloadLink.href = svgUrl;
    downloadLink.download = `${name}.svg`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    // reseting font-family property for our beloved <Customize />
    Def.canvas.getObjects().map(obj => {
        if (obj.text) {
            let ind = 0;

            obj.text.split('').forEach(c => {
                const item = obj.item(ind);
                if (item) {
                    item.set("fontFamily", item.oldFontFamily)
                }
                ind++
            })

            obj.set('fontFamily', obj.oldFontFamily);
        }
    });

    Def.canvas.renderAll();
}

export const ShowMaterial = (id: string) => {
    const obj = Def.canvas.getObjects().find(d => d.id === id);
    if (obj) {
        obj.opacity = 1;
        Def.canvas.renderAll();
    }
}

export const RenderAll = () => Def.canvas.renderAll();

const getZIndex = (object, canvas) => {
    Def.canvas.getObjects().indexOf(object);
}

const setZIndex = (object, zIndex, canvas) => {
    while (getZIndex(object, canvas) > zIndex) {
        canvas.sendBackwards(object);
    }
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 150,
            padding: 0,
            // maxHeight: 20,
        },
        selectEmpty: {
            marginTop: theme.spacing(1),
        },
        input: {
            width: 42,
        },
    })
)

const StyledMenu = withStyles({
    paper: {
        width: '353px',
        border: '1px solid #DADEE3',
        boxSizing: 'border-box',
        borderRadius: '4px',
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "left",
        }}
        {...props}
    />
))

const StyledMenuItem = withStyles(theme => ({
    root: {
    },
}))(MenuItem)

export const Customize = ({ text, setText, hideTopbar = false, lock = false }) => {
    const classes = useStyles();
    const containerRef = useRef(null);
    const deleteButtonRef = useRef(null);
    const [isMouseInCanvas, setIsMouseInCanvas] = useState(false);
    const { setVisible } = useContext(MenuContext);
    const { isMobile } = useContext(WindowResolutionContext);

    const [values, setValue] = React.useState<{
        angle: number,
        lineSpace: number,
        charSpacing: number,
        fontFamily: string,
        fill: string,
        fontWeight: string,
        fontStyle: string,
        visibility: Boolean,
    }>({
        angle: 0,
        lineSpace: 0,
        charSpacing: 0,
        fontWeight: 'normal',
        fontFamily: 'MrDeHaviland-Regular',
        fill: 'black',
        fontStyle: 'normal',
        visibility: true,
    })

    const { boundaries, isAdmin, updateItemBoundary, addUndoRedo, updateUserImageBoundary, isCanvasLoaded } = useContext(EditProductContext);
    const [zoomValue, setZoomValue] = useState(.1);

    const [syncUndoRedo, setSyncUndoRedo] = useState<any>({});

    const [syncUndoRedoBefore, setSyncUndoRedoBefore] = useState<any>({});
    const [syncUndoRedoAfter, setSyncUndoRedoAfter] = useState<any>({});

    Def.isAdmin = isAdmin;

    let resizeCheck = 25;
    let intervalId;

    useEffect(() => {
        return () => {
            setVisible(true);
        }
    }, []);

    useEffect(() => {
        Def.init(setValue, setText, () => {
            resizeCanvas();
            intervalId = setInterval(() => {
                resizeCanvas();
                resizeCheck--;
                if (resizeCheck < 0) {
                    clearInterval(intervalId);
                }
            }, 10);

            Def.canvas.on('before:transform', (e) => {
                setSyncUndoRedoBefore({
                    type: IUndoRedoType.Transform,
                    transform: {
                        scaleX: e.transform.target.scaleX,
                        scaleY: e.transform.target.scaleY,
                        left: e.transform.target.left,
                        top: e.transform.target.top,
                        angle: e.transform.target.angle
                    },
                    object: e.transform.target
                });
            });

            Def.canvas.on('object:modified', (e) => {
                setSyncUndoRedoAfter({
                    type: IUndoRedoType.Transform,
                    transform: {
                        scaleX: e.transform.target.scaleX,
                        scaleY: e.transform.target.scaleY,
                        left: e.transform.target.left,
                        top: e.transform.target.top,
                        angle: e.transform.target.angle
                    },
                    object: e.transform.target
                });
            });

            isAdmin && initDeleteButtons();
        });

        setVisible(false);
    }, []);

    useEffect(() => {
        Def.canvas.selection = !lock;
    }, [lock]);

    useEffect(() => {
        if (_.isEmpty(syncUndoRedoAfter)) {
            return;
        }
        addUndoRedo(syncUndoRedoBefore, syncUndoRedoAfter);
    }, [syncUndoRedoAfter]);

    useEffect(() => {
        const mouseDownEvent = (e) => {
            if (!Def.canvas.getActiveObject() && isMouseInCanvas) {
                document.body.classList.add('grab-cursor');
            }
        }

        const mouseUpEvent = (e) => {
            document.body.classList.remove('grab-cursor');
        }

        document.addEventListener('mousedown', mouseDownEvent);
        document.addEventListener('mouseup', mouseUpEvent);

        return () => {
            document.removeEventListener('mousedown', mouseDownEvent);
            document.removeEventListener('mouseup', mouseUpEvent);
        }
    }, [isMouseInCanvas]);

    useEffect(() => {
        initBoundaries();
    }, [boundaries]);

    const resizeCanvas = () => {
        const ratio = CANVAS_WIDTH / CANVAS_HEIGHT;
        const containerWidth = containerRef.current.offsetWidth - 12;
        const containerHeight = containerRef.current.offsetHeight - 64;
        let scaleX, newWidth, newHeight;

        if (containerWidth < containerHeight) {
            scaleX = containerWidth / CANVAS_WIDTH;
            newWidth = containerWidth;
            newHeight = containerWidth / ratio;
        } else {
            scaleX = containerHeight / CANVAS_HEIGHT;
            newWidth = containerHeight;
            newHeight = containerHeight / ratio;
        }

        if (!Def.canvas) {
            return;
        }

        Def.canvas.setDimensions({ width: newWidth, height: newHeight });
        Def.canvas.setViewportTransform([scaleX, 0, 0, scaleX, 0, 0]);
        Def.canvas.renderAll();
    }

    const initDeleteButtons = () => {
        if (!isAdmin) {
            return;
        }

        deleteButtonRef.current.style.display = 'none';

        const place = (e: any) => {
            let left = e.target.oCoords.tr.x + 10;
            const top = e.target.oCoords.tr.y - 30;
            deleteButtonRef.current.style.top = top;
            deleteButtonRef.current.style.left = left;
            deleteButtonRef.current.style.transform = `rotate(${e.target.angle}deg)`;
            deleteButtonRef.current.style.display = 'block';
        }

        Def.canvas.on('selection:created', place);
        // Def.canvas.on('object:moving', place);
        // Def.canvas.on('object:scaling', place);
        // Def.canvas.on('object:rotating', place);

        Def.canvas.on('mouse:down', (e: any) => {
            if (!Def.canvas.getActiveObject()) {
                deleteButtonRef.current.style.display = 'none';
            }
        });
    }

    let userImageBoundaryBox;

    const { getImageBoundaryId } = useContext(EditProductContext);

    const initBoundaries = () => {
        Def.canvas.on('selection:created', (e) => {
            if (e.target.id && (e.target.id.includes('user-image'))) {
                userImageBoundaryBox = Def.canvas.getObjects().find(d => d.id === e.target.boundaryId);
                updateUserImageBoundary(e.target, userImageBoundaryBox);
            }
        })

        Def.canvas.on('object:moving', (e) => {
            updateItemBoundary(e.target);
            if (e.target.id && (e.target.id.includes('user-image'))) {
                if (userImageBoundaryBox) {
                    updateUserImageBoundary(e.target, userImageBoundaryBox);
                } else {
                    userImageBoundaryBox = Def.canvas.getObjects().find(d => d.id === e.target.boundaryId);
                }
            }
        });
        Def.canvas.on('object:scaling', (e) => {
            updateItemBoundary(e.target);
            if (e.target.id.includes('user-image')) {
                if (userImageBoundaryBox) {
                    updateUserImageBoundary(e.target, userImageBoundaryBox);
                } else {
                    userImageBoundaryBox = Def.canvas.getObjects().find(d => d.id === e.target.boundaryId);
                }
            }
        });
        Def.canvas.on('object:rotating', (e) => {
            updateItemBoundary(e.target);
            if (e.target.id.includes('user-image')) {
                if (userImageBoundaryBox) {
                    updateUserImageBoundary(e.target, userImageBoundaryBox);
                } else {
                    userImageBoundaryBox = Def.canvas.getObjects().find(d => d.id === e.target.boundaryId);
                }
            }
        });
    }


    const sendToBack = () => {
        const activeObj = Def.canvas.getActiveObject()
        if (activeObj) {
            Def.canvas.sendBackwards(activeObj)
        }
    }

    const sendToFront = () => {
        const activeObj = Def.canvas.getActiveObject()
        if (activeObj) {
            Def.canvas.bringForward(activeObj)
        }
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', resizeCanvas);
        resizeCanvas();
        return () => window.removeEventListener('resize', resizeCanvas);
    }, []);

    useEffect(() => {
        Def.changeGroupText(text)
    }, [text])

    const fontHandler = e => {
        setValue({ ...values, 'fontFamily': e.target.value })
        const font = fonts.find(d => d.name === e.target.value);
        Def.changeStyle(4, { fontFamily: e.target.value, fontFileName: font.filename, fontUrl: font.url })
    }
    const colorHandler = e => {
        Def.changeStyle(6, e.target.value)
        setValue({ ...values, 'fill': e.target.value })
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [alignmentAnchor, setAlignmentAnchor] = React.useState<null | HTMLElement>(null);

    const handleSliderChange = (key, value) => {
        let x = value
        if (typeof value == 'string') {
            if (value != '') value = x = parseInt(value)
            else x = 0
        }

        if (key == 'charSpacing') Def.changeStyle(7, x);
        if (key == 'lineSpace') Def.changeStyle(8, x);
        if (key == 'angle') Def.changeStyle(9, x);
        setValue({ ...values, [key]: value })
    }

    const onZoomChange = (event, newZoomValue) => {
        setZoomValue(newZoomValue);

        if (zoomValue > newZoomValue) {
            Def.canvas.zoomToPoint({ x: Def.canvas.getWidth() / 2, y: Def.canvas.getHeight() / 2 }, Math.max(0.1, Def.canvas.getZoom() - (zoomValue - newZoomValue)));
        } else {
            Def.canvas.zoomToPoint({ x: Def.canvas.getWidth() / 2, y: Def.canvas.getHeight() / 2 }, Math.min(2, Def.canvas.getZoom() + (newZoomValue - zoomValue)));
        }
        // Def.canvas.setZoom(newZoomValue);
    }

    const { fonts, getUrl: getFontUrl } = useContext(FontContext);
    const { setReadyToDownload } = useContext(EditProductContext);

    useEffect(() => {
        if (!fonts) {
            return;
        }

        (async () => {
            const fontUrls = await Promise.all(
                fonts.filter(font => font.filename && font.name)
                    .map(async font => {
                        return {
                            name: font.name,
                            url: await getFontUrl(font.filename)
                        }
                    })
            );

            if (fontUrls.length === 0) {
                return;
            }

            fontUrls.forEach(font => fabric.fontPaths[font.name] = font.url);
            setReadyToDownload(true);
        })();

    }, [fonts]);

    return (
        <div style={{ height: '100%' }}>
            <div
                className="customize-container" ref={containerRef}
                style={{ height: '100%' }}
                onMouseEnter={(e) => {
                    setIsMouseInCanvas(true)
                }}
                onMouseLeave={(e) => {
                    setIsMouseInCanvas(false)
                }}
            >
                <div style={{ visibility: values.visibility && !hideTopbar ? 'visible' : 'hidden', display: 'flex', alignItems: 'center' }}>
                    <FormControl className={classes.formControl} variant="outlined" style={{ width: isMobile ? 120 : 234 }}>
                        <Select
                            MenuProps={MenuPropsCustom}
                            className='custom-select'
                            id="demo-simple-select"
                            value={values.fontFamily}
                            onChange={fontHandler}
                            defaultValue='default'
                            style={{ fontFamily: (values.fontFamily != 'default' ? values.fontFamily : null) }}
                        >
                            {fonts.map(value => {
                                return (
                                    <MenuItem
                                        className='custom-option'
                                        value={value.name}
                                        style={{ fontStyle: "normal", fontFamily: (value.name != 'default' ? value.name : null) }}
                                    >
                                        {value.name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    <Button onClick={(e) => setAnchorEl(e.currentTarget)} style={{ maxWidth: "30px", minWidth: "30px", marginLeft: "0", transform: 'scale(1.2)' }}>
                        <img style={{ height: 16 }} src={Texte} />
                    </Button>
                    <StyledMenu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={() => {
                            setAnchorEl(null);
                        }}
                        PaperProps={isMobile ? { style: { transform: 'scale(0.75, 0.5625)', marginLeft: 64 } } : {}}
                    >
                        <StyledMenuItem style={{ display: 'flex', alignItems: 'center' }} dense={isMobile}>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around', alignItems: 'center' }}>
                                <p style={{ minWidth: '80px', marginTop: 0, marginBottom: 0 }}>Courbe</p>
                                <Slider
                                    id='angle'
                                    name='angle'
                                    min={-360}
                                    max={360}
                                    style={{ width: '90%' }}
                                    value={values.angle}
                                    onChange={(e, num) => handleSliderChange('angle', num)}
                                    aria-labelledby="angle-slider"
                                />
                                <div style={{ marginLeft: '4px' }}>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        value={values.angle}
                                        onChange={(e) => handleSliderChange('angle', e.target.value)}
                                        type='number'
                                        style={{ height: 5 }}
                                        inputProps={{
                                            step: 'none',
                                            min: -360,
                                            max: 360,
                                            type: 'number',
                                            style: { height: isMobile ? 5 : 10 },
                                            'aria-labelledby': 'input-slider',
                                        }}
                                    />
                                </div>
                            </div>
                        </StyledMenuItem>
                        <StyledMenuItem style={{ display: 'flex', alignItems: 'center' }} dense={isMobile}>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around', alignItems: 'center' }}>
                                <p style={{ minWidth: '80px', marginTop: 0, marginBottom: 0 }}>Interligne</p>
                                <Slider
                                    name='lineSpce'
                                    min={-20}
                                    max={20}
                                    style={{ width: '90%' }}
                                    value={values.lineSpace}
                                    onChange={(e, num) => handleSliderChange('lineSpace', num)}
                                    aria-labelledby="input-slider"
                                />
                                <div style={{ marginLeft: '4px' }}>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        value={values.lineSpace}
                                        onChange={(e) => handleSliderChange('lineSpace', e.target.value)}
                                        type='number'
                                        inputProps={{
                                            step: 10,
                                            min: 0,
                                            max: 500,
                                            type: 'number',
                                            style: { height: isMobile ? 5 : 10 },
                                            'aria-labelledby': 'input-slider',
                                        }}
                                    />
                                </div>
                            </div>

                        </StyledMenuItem>
                        <StyledMenuItem style={{ display: 'flex', alignItems: 'center' }} dense={isMobile}>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around', alignItems: 'center' }}>
                                <p style={{ minWidth: '80px', marginTop: 0, marginBottom: 0 }}>Espace</p>
                                <Slider
                                    name='charSpacing'
                                    style={{ width: '90%' }}
                                    value={values.charSpacing}
                                    onChange={(e, num) => handleSliderChange('charSpacing', num)}
                                    aria-labelledby="input-slider"
                                />
                                <div style={{ marginLeft: '4px' }}>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        style={{ marginLeft: '4px !important' }}
                                        value={values.charSpacing}
                                        onChange={(e) => handleSliderChange('charSpacing', e.target.value)}
                                        type='number'
                                        inputProps={{
                                            step: 10,
                                            min: 0,
                                            max: 100,
                                            type: 'number',
                                            style: { height: isMobile ? 5 : 10 },
                                            'aria-labelledby': 'input-slider',
                                        }}
                                    />
                                </div>
                            </div>
                        </StyledMenuItem>
                    </StyledMenu>
                    {/* <Button
            style={{ maxWidth: "30px", minWidth: "30px", marginLeft: "24" }}
            onClick={() => Def.addText(text, values, '', 'black')}
          >
            +
          </Button> */}
                    <Button
                        style={{ maxWidth: "30px", minWidth: "30px", marginLeft: "0", transform: 'scale(1.2)' }}
                        onClick={() => Def.changeStyle(1, "Bold")} // Def.changeStyle(1, "Bold")
                    >
                        <img style={{ height: 16 }} src={Bold} />
                    </Button>
                    <Button
                        style={{ maxWidth: "30px", minWidth: "30px", marginLeft: "0", transform: 'scale(1.2)' }}
                        onClick={() => Def.changeStyle(2, "Italic")}
                    >
                        <img style={{ height: 16 }} src={Italie} />
                    </Button>
                    <Button
                        style={{ maxWidth: "30px", minWidth: "30px", marginLeft: "0", transform: 'scale(1.2)' }}
                        onClick={() => Def.changeStyle(3, "Underline")}
                    >
                        <img style={{ height: 16 }} src={Usvg} />
                    </Button>

                    <Button onClick={(e) => setAlignmentAnchor(e.currentTarget)} style={{ minWidth: 0, transform: 'scale(1.2)' }}>
                        <img style={{ height: 16 }} src={CenterAlign} />
                    </Button>
                    <StyledMenu
                        id="customized-menu"
                        anchorEl={alignmentAnchor}
                        keepMounted
                        open={Boolean(alignmentAnchor)}
                        onClose={() => setAlignmentAnchor(null)}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <Button onClick={() => Def.changeStyle(10, 'left')}> <img style={{ width: '23px' }} src={LeftAlign} /> </Button>
                            <Button onClick={() => Def.changeStyle(10, 'center')}> <img style={{ width: '23px' }} src={CenterAlign} /> </Button>
                            <Button onClick={() => Def.changeStyle(10, 'right')}><img style={{ width: '23px' }} src={RightAlign} /></Button>
                        </div>
                    </StyledMenu>
                    {/* <Button
            style={{ maxWidth: "30px", minWidth: "3013pxpx", marginLeft: "24" }}
            onClick={() => {
              Def.changeStyle(5, "Delete");
              if (Def.canvas.getActiveObject()) {
                deleteButtonRef.current.style.display = 'none';
              }
            }}
          >
            X
          </Button>
          <input
            value={values.fill}
            onChange={colorHandler}
            style={{ marginLeft: "10px" }}
            type="color"
          /> */}

                </div>

                <div id="canvas-container" style={{ position: 'relative', height: 'calc(100% - 16px)', alignItems: isMobile ? 'center' : 'flex-start' }}>
                    <canvas id="customize" style={{ position: 'absolute', border: isAdmin ? '1px solid black' : 'none' }}></canvas>


                    {!isCanvasLoaded && (
                        <div style={{ width: '100%', height: '100%', position: 'absolute' }}>
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, calc(-50% - 48px))' }}>
                                <CircularProgress />
                            </div>
                        </div>
                    )}

                    {isAdmin && (
                        <div id="delete-button" ref={deleteButtonRef}>
                            <ArrowDropDown fontSize="large" onClick={sendToBack} />
                            <ArrowDropUp fontSize="large" onClick={sendToFront} />
                        </div>
                    )}
                </div>
            </div>

            <div style={{ position: 'absolute', bottom: 10, display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between', padding: '0 30px', width: '100%' }}>
                <div style={{ minWidth: 200 }}>
                    <Zoom zoomValue={zoomValue} onZoomChange={onZoomChange} />
                </div>
                <div className="mobile">
                    <UndoRedo isMobile />
                </div>
            </div>
        </div>
    )
}
