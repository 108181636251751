import React, { useState, useEffect } from 'react'
import { useFirestoreCollection } from '../firebase/firebase'
import TopBar from '../components/Models/topBar';
import Table from '../components/Models/table/table'
import InsertModel from '../components/Models/insertModel/insertModel';
import PrivateRoute from './../components/PrivateRoute';


const ModelsPage = () => {
    const [addClicked, setAddClicked] = useState<Boolean>(false)
    const [menusList, setMenuList] = useState<Array<string>>(['Tous les modèles'])
    let { data, loading } = useFirestoreCollection(['models'])
    const [searchValue, setSearchValue] = useState<string>(null)
    useEffect(() => {
        if (addClicked) setMenuList([...menusList, 'Ajouter un nouveau modèle'])
        else setMenuList(['Tous les modèles'])
    }, [addClicked])
    if (loading) {
        return <h1>Loading...</h1>;
    }
    return (
        <PrivateRoute>
            <TopBar searchValue={searchValue} setSearchValue={setSearchValue} menuList={menusList} addClicked={addClicked} setAddClicked={setAddClicked}></TopBar>
            {!addClicked ? <Table data={data}></Table> : <InsertModel setAddClicked={setAddClicked}></InsertModel>}
        </PrivateRoute>
    )
}


export default ModelsPage;