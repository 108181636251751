import React, { useRef } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { CenterImage, ChangeBackgroundImage, MakeBgVisible, PositionAndScaleBg, ToggleGuideLineBox } from '../../components/Customize/Customize';
import { EditProductContext, IUndoRedoType } from '../../contexts/EditProductContext';
import * as _ from 'lodash';
import './style.scss';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, CircularProgress, FormControlLabel, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '../../components/Button/button';
import EditModelColor from '../../components/Models/EditModel/EditModelColor';
import Lock from '../../components/Lock';
import CropFreeIcon from '@material-ui/icons/CropFree';


const ChangeBackground = () => {
    const {
        backgroundImageSrcs,
        product,
        setSelectedBgIndex,
        selectedBgIndex,
        changeBackgoundImage,
        bgUploading,
        isAdmin,
        selectedPageId,
        hasMonoColor,
        setHasMonoColor,
        resetColor,
        applyColor,
        toggleLockedItem,
        isLocked,
        addUndoRedo
    } = useContext(EditProductContext);
    const inputFileRefs = _.range(100).map(d => useRef(null));

    const selectDefaultBg = (index) => {
        addUndoRedo({
            type: IUndoRedoType.MaterialChange,
            materialIndex: selectedBgIndex
        }, {
            type: IUndoRedoType.MaterialChange,
            materialIndex: index
        })

        setSelectedBgIndex(index);
        MakeBgVisible(selectedPageId, index, isAdmin);
    }

    
    const materialOptions = _.find(_.get(product, 'options') || [], (d) => d.name === 'Matériel') || {};
    
    const onToggleLock = (index) => {
        const bgId = `${selectedPageId}-background-image-${index}`;
        toggleLockedItem(bgId);
    }

    return (
        <div>
            {(materialOptions.values || []).map((materialName, index) => (
                <Accordion key={index}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <FormControlLabel
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                            control={<Checkbox color="primary" onClick={() => selectDefaultBg(index)} checked={selectedBgIndex === index} />}
                            label={materialName || 'Material'}
                        />
                    </AccordionSummary>
                    <AccordionDetails style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {backgroundImageSrcs && backgroundImageSrcs[index] && (
                            <div>
                                <div className="EditProduct-material-round-image" style={{ backgroundImage: `url(${backgroundImageSrcs[index]})` }}></div>
                                <div style={{ position: 'relative' }}>
                                    <img src={backgroundImageSrcs[index]} style={{ width: '100%' }} />
                                    {bgUploading[index] && <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}><CircularProgress /></div>}
                                </div>
                            </div>
                        )}
                        <div></div>
                        <input ref={inputFileRefs[index]} type="file" onChange={e => changeBackgoundImage(index, e.target.files[0])} style={{ position: 'absolute', left: -100000 }} />
                        <Button name={"Changer l'image"} disable={bgUploading[index]} onclick={() => inputFileRefs[index].current.click()}></Button>
                        <div style={{ marginTop: 12 }}></div>
                        <Button name={"Centrer"} onclick={() => CenterImage(`${selectedPageId}-background-image-${index}`)}></Button>
                        {index > 0 && (
                            <div>
                                <div style={{ marginTop: 12 }}></div>
                                <Button name="Position en tant que premier" onclick={() => PositionAndScaleBg(selectedPageId, index)}></Button>
                            </div>
                        )}

                        <div style={{ marginTop: 12 }}></div>

                        <div style={{ cursor: 'pointer' }} onClick={() => onToggleLock(index)}>
                            <Lock locked={isLocked(`${selectedPageId}-background-image-${index}`)} />
                        </div>

                        <div style={{ marginTop: 12 }}></div>
                        <EditModelColor materialIndex={index} />
                    </AccordionDetails>
                </Accordion>
            ))}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            onClick={() => {
                                if (hasMonoColor) {
                                    resetColor();
                                } else {
                                    setHasMonoColor(true);
                                }
                            }}
                            checked={hasMonoColor}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label="Mono Color"
                />
                <div>
                    <Button name="Pour changer de ligne" onclick={() => ToggleGuideLineBox(0)}></Button>
                </div>
            </div>
        </div>
    )
}

export default ChangeBackground;
