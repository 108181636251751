import React, { useState, createContext } from 'react';
import { useHistory } from 'react-router-dom';


export const MenuContext = createContext<{
    state: any,
    dispatch: Function,
    selected: string,
    setSelected: Function,
    visible: boolean,
    setVisible: Function,
    isHiddenAdminText: boolean;
    setIsHiddenAdminText: Function;
}>(null)


const MenuContextProvider = ({children}) => {
    const history = useHistory();
    const [visible, setVisible] = useState(true);
    const [state, dispatch] = useState<Object>({
        0: {
            title: "Tableau de bord",
            expanded: false,
            items: [
                // { text: "Home", onClick: () => history.push('/') },
                { text: "Fonts", onClick: () => history.push('/fonts') },
                // { text: "Le Rapport" },
            ]
        },
        1: {
            title: "Des produits",
            expanded: false,
            items: [
                { text: "Tous les produits", onClick: () => history.push('/products') },
                // { text: "Ajouter un nouveau produit", onClick: () => history.push('productCustomize') },
                // { text: "Catégories de produits" },
            ]
        },
        2: {
            title: "Paramètres",
            expanded: false,
            items: [
                { text: "Changer le mot de passe", onClick: () => history.push('/change-password') },
            ]
        },
    })
    const [selected, setSelected] = useState<string>(null);

    const [isHiddenAdminText, setIsHiddenAdminText] = useState(true);

    return <MenuContext.Provider value={{
        state, 
        dispatch, 
        selected, 
        setSelected,
        visible,
        setVisible,
        isHiddenAdminText,
        setIsHiddenAdminText
    }}>
        {children}
    </MenuContext.Provider>
}

export default MenuContextProvider;