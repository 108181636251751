import { Checkbox, FormControlLabel } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { SketchPicker } from 'react-color';
import { EditModelContext } from '../../../contexts/EditModelContext';
import { EditProductContext } from '../../../contexts/EditProductContext';
import { rgbToString } from '../../Customize/Customize';

export const DEFAULT_MODEL_COLOR = { r: 0, g: 0, b: 0, a: 1 };

const EditModelColor = ({ materialIndex }) => {
    const { 
        hasMonoColor, 
        colors,
        changeColor,
        resetColor
    } = useContext(EditProductContext);
    const color = colors[materialIndex] != null ? colors[materialIndex] : DEFAULT_MODEL_COLOR;

    const [colorPickerOpen, setColorPickerOpen] = useState(false);

    return (
        <div>
            {hasMonoColor && (
                <div style={{ position: 'relative' }}>
                    <div style={{ border: '2px solid black', borderRadius: 2, padding: 2, display: 'inline-block' }} onClick={() => setColorPickerOpen(!colorPickerOpen)}>
                        <div style={{ width: 32, height: 16, cursor: 'pointer', backgroundColor: rgbToString(color) }}></div>
                    </div>
                    {colorPickerOpen && (
                        <div>
                            <div onClick={() => setColorPickerOpen(false)} style={{ zIndex: 30000000 - 1, height: '100vh', width: '100vw', position: 'fixed', top: 0, left: 0 }}></div>
                            <div style={{ position: 'absolute', left: -93, zIndex: 30000000 }}>
                                <SketchPicker disableAlpha presetColors={['#714720']} color={color} onChange={(color) => {
                                    changeColor(materialIndex, color.rgb)
                                } } />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default EditModelColor;
