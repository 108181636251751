import { Checkbox, FormControlLabel } from '@material-ui/core';
import React, { useContext } from 'react';
import { USER_IMAGE_BOUNDARY_ID, MakeImageBoundaryVisible } from '../../../components/Customize/Customize';
import Lock from '../../../components/Lock';
import { EditModelContext } from '../../../contexts/EditModelContext';
import { EditProductContext } from '../../../contexts/EditProductContext';

const EditImages = () => {
    const {
        isLocked,
        toggleLockedItem,
        isAdmin,
        askHasImage,
        turnOffImage,
        turnOnImage,
        getImageBoundaryId
    } = useContext(EditProductContext);

    // const { hasImages, turnOffImage, turnOnImage, getImageBoundaryId, modelId } = useContext(EditModelContext);

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            onClick={() => {
                                if (askHasImage()) {
                                    turnOffImage();
                                    const imageBoundaryId = getImageBoundaryId();
                                    if (isLocked(imageBoundaryId)) {
                                        toggleLockedItem(imageBoundaryId);
                                    }
                                } else {
                                    turnOnImage();
                                }
                            }}
                            checked={askHasImage()}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label="A-t-il des images ?"
                />
            </div>
            {askHasImage() && (
                <div>
                    <div style={{ marginTop: 12 }}></div>
                    <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }} onClick={() => { toggleLockedItem(getImageBoundaryId());}}>
                        <Lock locked={isLocked(getImageBoundaryId())} />
                    </div>
                </div>
            )}
        </div>
    )
}

export default EditImages;
