import fetch from 'cross-fetch';
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

export const client = new ApolloClient({
  link: new HttpLink({
    uri: `https://monzayadev.myshopify.com/api/2021-07/graphql.json`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/graphql',
      'X-Shopify-Storefront-Access-Token': `c0152d119fd00fbebcc3e8e539a72ecf`,
    },
    fetch
  }),
  cache: new InMemoryCache(),
});
