import React, {FC} from 'react'
import './style.scss'

type ButtonType = {
    name?: string,
    onclick?: any,
    disable?: boolean
}

const Button: FC <ButtonType> = (props) => {
    const {name, onclick, disable} = props;
    return (<>
        <button disabled={disable} onClick={onclick} className='default-button'>{name}</button>
    </>)
}

export default Button;