import react, {createContext, useState, useEffect} from 'react'
import { useFirestoreCollection, useFirestoreStorage } from '../firebase/firebase'

export const FontContext = createContext<{
    fonts: Array<any>;
    addFont: Function;
    getUrl: Function;
}>(null);

export const FontContextProvider = ({children}) => {
    const [fonts, setFonts] = useState([]);
    let { data, loading } = useFirestoreCollection(['fonts'])
    const { getUrl } = useFirestoreStorage(["fonts"])
    const addFont = (name:string, fontUrl: string) => {
        const styleEl = document.createElement('style');
        styleEl.innerHTML = `
            @font-face {
                font-family: ${name};
                src: url(${fontUrl});
            }
        `
        document.head.appendChild(styleEl);
    }
    useEffect(() => {
        if(!data) return
        data.forEach(elm => {
            getUrl(elm.filename).then(url => {
                addFont(elm.name, url)
            })
        })
        setFonts([{name: 'default'}, ...data])
    }, [loading])

    return <FontContext.Provider value={{
        fonts,
        addFont,
        getUrl
    }}>
        {children}
    </FontContext.Provider>
}