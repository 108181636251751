import React from 'react';
import { Lock as LockIcon, LockOpen as LockOpenIcon } from '@material-ui/icons';

const Lock = ({ locked }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {locked ? <LockIcon color="error" /> : <LockOpenIcon color="primary" />} <strong style={{userSelect: 'none'}}>Fermer à clé</strong>
        </div>
    )
}

export default Lock;
