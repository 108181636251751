import React, { useState, FC } from 'react'
import Button from '../../Button/button'
import './style.scss';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import { useFirestoreCollection, useFirestoreStorage } from '../../../firebase/firebase';
import { gridSortModelSelector } from '@material-ui/data-grid';
// import Insert from '../../../api/models';

type InsertModelType = {
    setAddClicked: Function,
}

const InsertModel : FC<InsertModelType> = ({setAddClicked}) => {
    const {createRecord, loading: loadingRec} = useFirestoreCollection(['models'])
    const {upload, loading: loadingImg} = useFirestoreStorage(['models/'])
    const [model, setModel] = useState<{data: File, url: string, status: boolean, name:string, filename: string, fullPath: string, package: string}>({
        data: null, url: null,  status: false, name: null, fullPath: null, package: null, filename: null,
    })

    const fileChange = (event) => {
        setModel({...model, data: event.target.files[0], url: URL.createObjectURL(event.target.files[0])})
    }

    const insert = async () => {
        if(model.data) {
            const resultUpload = await upload(model.data)
            if(resultUpload.state === 'success') {
                const result = await createRecord({name: model.name, packageID: model.package, fullPath: resultUpload.metadata.fullPath, status: model.status, filename: model.data.name})   
            }
        }
    }

    return (<>
        <div className='insert-container'>
            <div>
                <div className='insert-image'>
                    {model.url && <img src={model.url}/>}
                </div>
                <label className="input-button">
                    <input 
                        accept='.svg' 
                        id='file-upload'  
                        className='input-button' 
                        type='file' 
                        name={`Choisissez l'image du produit`} 
                        onChange={fileChange}
                    />
                    
                    <i className="fa fa-cloud-upload"></i>
                    Choisissez l'image du produit
                </label>
            </div>
            <div className='editor'>
                <p>Nom *</p>
                <input value={model.name} onChange={(e) => setModel({...model, name: e.target.value})} placeholder='Nom...'></input>
                <p>Pack *</p>
                <Select
                    className='select'
                    disableUnderline={true}
                    value={model.package}
                    onChange={(e) => {setModel({...model, package: ''+e.target.value})}}
                >
                    <MenuItem value={'1'}>Ten</MenuItem>
                    <MenuItem value={'2'}>Twenty</MenuItem>
                    <MenuItem value={'3'}>Thirty</MenuItem>
                </Select>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <p>Actif</p>
                    <Switch
                        color="primary"
                        checked={model.status}
                        onClick={(e) => {setModel({...model, status: !model.status})}}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </div>
                <Button disable={loadingImg || loadingRec} name='insert' onclick={insert}/>
            </div>
        </div>
    </>)
}

export default InsertModel;