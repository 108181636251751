import React, { useState, createContext } from 'react';
import { useHistory } from 'react-router-dom';
import useWindowDimensions from '../hooks/useWindowDimensions';


export const WindowResolutionContext = createContext<{
    width: number;
    height: number;
    isMobile: boolean;
}>(null)


const WindowResolutionContextProvider = ({children}) => {
    const { width, height } = useWindowDimensions();
    const isMobile = width <= 1200;

    return <WindowResolutionContext.Provider value={{
        width,
        height,
        isMobile
    }}>
        {children}
    </WindowResolutionContext.Provider>
}

export default WindowResolutionContextProvider;