import React, { useEffect, useState } from 'react';
import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: 'https://us-central1-monzaya-1f761.cloudfunctions.net',
});

export const useApi = (endPoint: string) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<any>({});
    const [error, setError] = useState<string>(undefined);
    const [retry, setRetry] = useState(0);

    useEffect(() => {
        if (endPoint.endsWith('null')) {
            return;
        }
        
        axiosInstance.get(endPoint)
            .then(res => {
                setData(res.data);
                setLoading(false);
            })
            .catch(e => {
                setError(e.toString());
                if (retry < 27) {
                    setRetry(retry + 1);
                }
                setLoading(false);
            });
            
    }, [endPoint, retry]);

    return {
        loading, data, error
    }
}
