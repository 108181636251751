import React from 'react';
import CropFreeIcon from '@material-ui/icons/CropFree';

const Boundary = ({ hasBoundary = false }) => {

    return (
        <div>
            <CropFreeIcon color={hasBoundary ? 'error' : 'primary'} />
        </div>
    )
}

export default Boundary;
