import React, {useState, useEffect} from 'react';
import './style.scss';
import { useFirestoreStorage } from '../../firebase/firebase';

const Model = (props) => {
    const {name, id, filename, addImage} = props;
    const {getUrl} = useFirestoreStorage(['models'])
    const [src, setSrc] = useState(null);
    
    
    useEffect(() => {
        getUrl(filename).then(url => setSrc(url))
    }, []);
      
      
    return (<div className='model' onClick={() => addImage(src)}>
        <div className='left'>
            <img src={src}></img>
        </div>
        <div className='right'>
            <p>{id}</p>
            <h3>{name}</h3>
        </div>
    </div>)
}

export default Model;