import React, { useState } from 'react'
import { Customize, AddImages } from '../components/Customize/Customize'
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Link from "@material-ui/core/Link"
import { Box } from '@material-ui/core'
import TextIcon from '../components/TextIcon/TextIcon'
import Material from '../components/Material/Material'
import './customize.scss';
import Model from '../components/Model/Model';
import { useFirestoreCollection } from '../firebase/firebase';
import TextField from '@material-ui/core/TextField';
import General from '../components/General/General'
import PrivateRoute from './../components/PrivateRoute';
import undoLeft from './../assets/icons/undo_left.png';
import undoRight from './../assets/icons/undo_right.png';

const CustomizePage = () => {
    const [selected, setSelected] = useState<number>(1);
    const [text, setText] = useState('change this text');
    let { data } = useFirestoreCollection(['models'])
    const addImage = (url) => {
        if (url) AddImages([url])
    }
    return (
        <PrivateRoute>
            <div style={{display: 'flex', height: 'calc(100vh - 120px)', backgroundColor: 'white'}}>
                <div style={{flex: 1}}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <Link color="inherit" href="/models">
                            Produit sélectionné
                        </Link>
                    </Breadcrumbs>
                    <div>
                        <h3>Livre d'or mariage</h3>
                    </div>

                    <Customize text={text} setText={setText}></Customize>
                </div>
                <Box style={{ width: 350, overflowY: 'scroll' }} className='mid-container'>
                    <div className='mid-header-container' style={{position: 'sticky', top: 0, backgroundColor: '#fafafa'}}>
                        <p>JE PERSONNALISE</p>
                        <h3 style={{ marginTop: -12 }}>Le modèle</h3>
                    </div>
                    <div className='mid-mid-container'>
                        {selected == 1 && <>
                            <Material />
                            <div>
                                {data.map(elm => {
                                    return <Model id={elm.id} fullPath={elm.fullPath} filename={elm.filename} name={elm.name} addImage={addImage}></Model>
                                })}
                            </div>
                        </>}
                        {selected == 2 && <>
                            <TextField title='Text' value={text} onChange={(e) => setText(e.target.value)} multiline placeholder='Text'></TextField>
                            {/* <Input title='Nom2' value={inputValues[1]} setValue={(e) => setInputValues({ ...inputValues, val1: e })} placeholder='Nom 2'></Input> */}
                            {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <p style={{ margin: 0 }}>Date</p>
                                <Switch
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </div>
                            <Input></Input> */}
                        </>}
                        {selected == 3 && <General></General>}
                    </div>
                </Box>

                <Box className='right-container'>
                    <Box display='flex' className='right-header-container'>
                        <button style={{ cursor: 'pointer' }}>
                            <img src={undoLeft} alt="prev" />
                        </button>
                        <button style={{ cursor: 'pointer' }}>
                            <img src={undoRight} alt="next" />
                        </button>
                    </Box>
                    <Box className='choices'>
                        <TextIcon name='Le modèle' onClick={() => setSelected(1)} id={1} selected={selected} />
                        <TextIcon name='Le texte' onClick={() => setSelected(2)} id={2} selected={selected} />
                        <TextIcon name='Général' onClick={() => setSelected(3)} id={3} selected={selected} />
                    </Box>
                </Box>
            </div>
        </PrivateRoute>
    )
}

export default CustomizePage;