import React, { useState } from "react"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import Button from "@material-ui/core/Button"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"
import TextareaAutosize from "@material-ui/core/TextareaAutosize"
import './style.scss';

const General = () => {
  const [chosen, setChosen] = useState("Ten")
  const [count, setCount] = useState(1)

  return (
    <div className='general-container'>
      <p className='title'>Selectioner les pages *</p>
      <Select
        className="select"
        disableUnderline={true}
        value={chosen}
        style={{ minWidth: "95%", maxWidth: "95%", background: '#FFFFFF', border: '1px solid #858C94', borderRadius: '8px', height: '48px', paddingLeft: '16px'}}
        onChange={(e: any) => setChosen(e.target.value)}
      >
        <MenuItem value={"1"}>Ten</MenuItem>
        <MenuItem value={"2"}>Twenty</MenuItem>
        <MenuItem value={"3"}>Thirty</MenuItem>
      </Select>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
        <p className='description'>80 pages bland</p>
        <p className='price' style={{ marginRight: "20px" }}>+15USD</p>
      </div>

      <div>
        <p className='title'>Quantité *</p>

        <div>
          <ButtonGroup style={{background: '#FFFFFF'}}>
            <Button
              style={{
                paddingRight: "0px",
                borderColor: "#858C94",
                borderRight: 0,
              }}
              onClick={() => {
                setCount(Math.max(count - 1, 0))
              }}
            >
              <RemoveIcon fontSize="small" />
            </Button>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderTop: "1px solid #858C94",
                borderBottom: "1px solid #858C94",
                width: "2px",
              }}
            >
              {count}
            </div>
            <Button
              style={{
                paddingLeft: 0,
                borderLeft: "0",
                borderColor: "#858C94",
              }}
              onClick={() => {
                setCount(count + 1)
              }}
            >
              <AddIcon fontSize="small" />
            </Button>
          </ButtonGroup>
          <p className='description'>{count} unité</p>
        </div>
      </div>

      <div>
        <p className='title'>La note</p>
        <TextareaAutosize
          rowsMin={3}
          style={{
            maxWidth: "95%",
            minWidth: "95%",
            border: "1px solid #858C94",
            borderRadius: "8px",
          }}
          placeholder="Exemple: veuillez livrer avant jeudi prochain."
        />
      </div>
    </div>
  )
}

export default General
