import React, { createContext, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { EditProductContext } from './EditProductContext';




export const UserCustomizeTextContext = createContext<{
    texts: string[];
    changeUserText: Function;
}>(null)


const UserCustomizeTextProvider = ({ children }) => {
    const { models, selectedModelId } = useContext(EditProductContext);
    const [texts, setTexts] = useState<string[]>([]);
    
    useEffect(() => {
        const { texts: modelTexts = [] } = _.get(models, [selectedModelId], {});
        setTexts(modelTexts);
    }, [selectedModelId])

    const changeUserText = (index: number, value: string) => {
        texts[index] = value;
        setTexts([...texts]);
    }

    return <UserCustomizeTextContext.Provider value={{
        texts,
        changeUserText
    }}>
        {children}
    </UserCustomizeTextContext.Provider>
}

export default UserCustomizeTextProvider;