import React, { useContext, useEffect } from 'react';
import { EditProductContext, IUndoRedoType } from '../../contexts/EditProductContext';
import * as _ from 'lodash';
import './MaterialSelection.scss';
import { MakeBgVisible } from '../../components/Customize/Customize';
import { UserSelectionContext } from '../../contexts/UserSelectionContext';
import { WindowResolutionContext } from '../../contexts/WindowResolutionContext';

const MaterialSelection = () => {
    const { 
        product, 
        firestoreData, 
        selectedBgIndex, 
        setSelectedBgIndex, 
        isAdmin, 
        selectedPageId, 
        backgroundImageSrcs,
        addUndoRedo,
        changeMaterial
    } = useContext(EditProductContext);
    const { setMaterial } = useContext(UserSelectionContext);

    const materialOptions = _.find(_.get(product, 'options') || [], (d) => d.name === 'Matériel') || {};
    const selectedMaterialName = _.get(materialOptions, ['values', selectedBgIndex], '');

    const { isMobile } = useContext(WindowResolutionContext);

    const selectMaterial = (index) => {
        addUndoRedo({
            type: IUndoRedoType.MaterialChange,
            materialIndex: selectedBgIndex,
        }, {
            type: IUndoRedoType.MaterialChange,
            materialIndex: index
        });
        setSelectedBgIndex(index);
        MakeBgVisible(selectedPageId, index, isAdmin);
    }

    if (backgroundImageSrcs == null) {
        return <div></div>;
    }

    const imageSize = isMobile ? 40 : 60;

    return (
        <div className="MaterialSelection">
            <div className="MaterialSelection-title">Sélectionnez la matière <span style={{ color: 'red' }}>*</span></div>
            <div className="MaterialSelection-images-container" style={{ margin: isMobile ? '6px 0' : '12px 0' }}>
                {backgroundImageSrcs.filter(d => d != null && d != "").map((url, index) => (
                    <div 
                        key={url}
                        className={`MaterialSelection-material-image ${index === selectedBgIndex && 'MaterialSelection-material-image-selected'}`} 
                        style={{ backgroundImage: `url(${url})`, height: imageSize, width: imageSize }}
                        onClick={() => selectMaterial(index)}
                    >
                    </div>
                ))}
            </div>
            <div className="MaterialSelection-name" style={{ margin: isMobile ? '4px 0' : '12px 0 20px' }}>{selectedMaterialName}</div>
        </div>
    )
}

export default MaterialSelection;
