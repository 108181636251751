import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Customize, Def, DownloadSVG, HideCurrentMaterial, LoadFromJson, ShowMaterial, ToggleUserImageBoundaryBoxVisibility, ToggleUserImagesVisibility, ToSVG } from '../../components/Customize/Customize';
import { getDoc, getRTDB, useFirestoreDocument } from '../../firebase/firebase';
import { Box, Checkbox, CircularProgress, FormControlLabel } from '@material-ui/core';
import * as _ from 'lodash';
import './style.scss';
import SideSectionHeader from '../CustomizeProductPage/SideSectionHeader';
import Button from '../../components/Button/button';
import { EditProductContext } from '../../contexts/EditProductContext';
import { FontContext } from '../../contexts/FontContext';

const FinishedProduct = () => {
    const [text, setText] = useState('');
    const { id } = useParams<any>();
    const [loading, setLoading] = useState(true);
    const [isMaterialHidden, setIsMaterialHidden] = useState(false);
    const [isImageBoundaryHidden, setIsImageBoundaryHidden] = useState(false);
    const [isImagesHidden, setIsImagesHidden] = useState(false);
    const [currentMaterialObjId, setCurrentMaterialObjId] = useState<string>(null);
    const [hasImages, setHasImages] = useState(false);
    const { readyToDownload } = useContext(EditProductContext);

    const { fonts, getUrl: getFontUrl } = useContext(FontContext);

    const [usedFonts, setUsedFonts] = useState([]);

    useEffect(() => {
        getRTDB(`addedToCart/${id}`).then(data => {
            console.log(data)
            if (_.get(data, 'canvasJson')) {
                LoadFromJson(_.get(data, 'canvasJson'), () => {
                    Def.canvas.getObjects().forEach(d => {
                        d.selectable = false;
                        d.evented = false;
                    });


                    Def.canvas.renderAll();

                    setUsedFonts(
                        _.chain(Def.canvas.getObjects())
                            .filter(d => d.text && d.opacity > 0)
                            .map(d => d)
                            .map(d => ({ fontFamily: d.fontFamily, fontFileName: d.fontFileName }))
                            .uniqBy('fontFamily')
                            .value()
                    );
                });
            }

            setHasImages(_.get(data, 'hasImages', false));
        }).catch(e => {
            console.log(e)
        });
    }, [id]);

    // if (loading) {
    //     return <CircularProgress />
    // }

    const toggleMaterialHide = () => {
        if (isMaterialHidden) {
            // show
            currentMaterialObjId && ShowMaterial(currentMaterialObjId);
            currentMaterialObjId && setIsMaterialHidden(!isMaterialHidden);
        } else {
            // hide
            const id = HideCurrentMaterial();
            setCurrentMaterialObjId(id);
            id && setIsMaterialHidden(!isMaterialHidden);
        }
    }

    const toggleImageBoundaryHide = () => {
        ToggleUserImageBoundaryBoxVisibility(isImageBoundaryHidden)
        setIsImageBoundaryHidden(!isImageBoundaryHidden);
    }

    const toggleUserImagesHide = () => {
        ToggleUserImagesVisibility(isImagesHidden);
        setIsImagesHidden(!isImagesHidden);
    }

    const downloadSVG = () => {
        DownloadSVG(id);
    }

    const downloadFont = async (fontFileName) => {
        const url = await getFontUrl(fontFileName);
        const linkEl = document.createElement('a');
        linkEl.href = url;
        linkEl.target = "_blank";
        linkEl.click();
    }

    return (
        <div>
            <div style={{ display: 'flex', height: 'calc(100vh - 80px)', backgroundColor: 'rgb(250, 250, 250)', overflowY: 'hidden' }}>
                <div style={{ flex: 1, position: 'relative' }}>
                    <div style={{ maxWidth: 800, margin: '0 auto', height: '100%' }}>
                        <Customize text={text} setText={setText} hideTopbar lock></Customize>
                    </div>
                </div>

                <div style={{ height: '100%', width: 350, display: 'flex', flexDirection: 'column' }}>
                    <div>
                        <SideSectionHeader primaryText="Des dossiers" secondaryText="JE PERSONNALISE" />
                    </div>
                    <Box style={{ overflowY: 'scroll', flex: 1 }} className='mid-container'>
                        <div className='mid-mid-container CPP-left-shadow' style={{ position: 'relative' }}>
                            {usedFonts.length !== 0 && (
                                usedFonts.map(font => (
                                    <div onClick={() => downloadFont(font.fontFileName)} key={font.fontFileName}>{font.fontFileName}</div>
                                ))
                            )}

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onClick={toggleMaterialHide}
                                        checked={isMaterialHidden}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Masquer le matériel"
                            />

                            {hasImages && (
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onClick={toggleImageBoundaryHide}
                                                checked={isImageBoundaryHidden}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="Masquer la zone de délimitation de l'image"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onClick={toggleUserImagesHide}
                                                checked={isImagesHidden}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="Masquer les images personnalisées"
                                    />
                                </div>
                            )}

                            <Button name="Télécharger" onclick={downloadSVG} disable={!readyToDownload} />
                        </div>
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default FinishedProduct;
