import { Box, Breadcrumbs, List, ListItem, ListItemText } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Customize } from '../../components/Customize/Customize';
import PageSelector from '../../components/PageSelector';
import TextIcon from '../../components/TextIcon/TextIcon';
import UndoRedo from '../../components/UndoRedo';
import { EditProductContext } from '../../contexts/EditProductContext';
import { MenuContext } from '../../contexts/MenuContext';
import SideSectionHeader from '../CustomizeProductPage/SideSectionHeader';
import ChangeBackground from './ChangeBackground';
import EditGeneral from './EditGeneral';
import EditImages from './EditImages';
import EditModels from './EditModels';
import EditPages from './EditPages';
import EditText from './EditText';
import TexteSrc from './../../assets/icons/texte.svg';
import GeneralSrc from './../../assets/icons/general.svg';
import ModelsSrc from './../../assets/icons/models.svg';
import ImagesSrc from './../../assets/icons/images.svg';
import DuplicateDialog from './DuplicateDialog';
import { getRTDB, updateRTDB, writeRTDB } from '../../firebase/firebase';

enum Section {
    BACKGROUND,
    MODEL,
    TEXT,
    GENERAL,
    PAGES,
    IMAGES
}

const EditProductPage = () => {
    const { id } = useParams<any>();
    const [text, setText] = useState('change this text');
    const [selectedSection, setSelectedSection] = useState(Section.BACKGROUND);
    const { setProductId, save, saving, setIsAdmin } = useContext(EditProductContext);
    const { setIsHiddenAdminText } = useContext(MenuContext);
    const [openDuplicateDialog, setOpenDuplicateDialog] = useState(false);
    const [duplicating, setDuplicating] = useState(false);

    const renderSideSectionTitle = () => {
        switch (selectedSection) {
            // case Section.BACKGROUND: return 'Matérial';
            // case Section.MODEL: return 'Modélé';
            // case Section.TEXT: return 'Text';
            // case Section.GENERAL: return 'Général';
            // case Section.PAGES: return 'Pages';
            // case Section.IMAGES: return 'Images';

            case Section.BACKGROUND: return 'Étape 1';
            case Section.MODEL: return 'Étape 2';
            case Section.IMAGES: return 'Étape 3';
            default: return 'Étape';
        }
        return '';
    }

    const renderSideSectionContent = () => {
        switch (selectedSection) {
            case Section.BACKGROUND: return <ChangeBackground />;
            case Section.MODEL: return <EditModels />;
            case Section.TEXT: return <EditText />;
            case Section.GENERAL: return <EditGeneral />;
            case Section.PAGES: return <EditPages />
            case Section.IMAGES: return <EditImages />
        }
    }

    useEffect(() => {
        setProductId(id);
        setIsAdmin(true);

        setIsHiddenAdminText(false);

        return () => {
            setProductId(null);
        }
    }, []);

    const duplicate = async (idToDuplicate: string) => {
        console.log(idToDuplicate, id);
        setDuplicating(true);
        const toDuplicateData = await getRTDB(`/products/${idToDuplicate}`);
        try {
            await writeRTDB(`products/${id}`, toDuplicateData);
        } catch (e) {
            await updateRTDB(`products/${id}`, toDuplicateData);
        }
        setDuplicating(false);
        setOpenDuplicateDialog(false);
        
        window.location.reload();
    }

    return (
        <div>
            <div style={{ display: 'flex', height: 'calc(100vh - 80px)', backgroundColor: 'rgb(250, 250, 250)', overflowY: 'hidden' }}>
                <div style={{ flex: 1, position: 'relative', height: '100%' }}>
                    <div style={{ margin: '0 auto', height: '100%' }}>
                        <Customize text={text} setText={setText}></Customize>
                    </div>
                    {/* <div style={{ position: 'absolute', top: 'calc((100vh - 80px) / 2)', right: 20, transform: 'translateY(-100%)' }}>
                        <PageSelector />
                    </div> */}
                </div>
                <Box style={{ width: 350, overflowY: 'scroll' }} className='mid-container'>
                    <div style={{ position: 'sticky', top: 0, backgroundColor: '#fafafa', zIndex: 10000 }}>
                        {/* <h2 style={{ marginTop: 12 }}>{renderSideSectionTitle()}</h2> */}
                        <SideSectionHeader primaryText={renderSideSectionTitle()} secondaryText="JE PERSONNALISE" />
                    </div>
                    <div className='mid-mid-container CPP-left-shadow'>
                        {renderSideSectionContent()}
                    </div>
                </Box>

                <Box className='right-container' style={{ boxShadow: '-1.25px 0px 4px rgba(0, 0, 0, 0.15)' }}>
                    <UndoRedo />

                    <Box className='choices'>
                        <TextIcon name='Étape 1' src={ModelsSrc} onClick={() => setSelectedSection(Section.BACKGROUND)} id={Section.BACKGROUND} selected={selectedSection} />
                        <TextIcon name='Étape 2' src={ModelsSrc} onClick={() => setSelectedSection(Section.MODEL)} id={Section.MODEL} selected={selectedSection} />
                        <TextIcon name='Étape 3' src={ImagesSrc} onClick={() => setSelectedSection(Section.IMAGES)} id={Section.IMAGES} selected={selectedSection} />
                    </Box>
                    
                    <List component="nav" aria-label="main mailbox folders">
                        <ListItem button onClick={() => setOpenDuplicateDialog(true)}>
                            <ListItemText primary={'Dupliquer'} />
                        </ListItem>
                        <ListItem button onClick={() => save()}>
                            <ListItemText primary={saving ? "S'il vous plaît, attendez..." : 'Sauvegarder'} />
                        </ListItem>
                    </List> 
                   
                </Box>

                <DuplicateDialog open={openDuplicateDialog} duplicating={duplicating} onDuplicate={(id) => {duplicate(id)}} />
            </div>
        </div>
    );
}

export default EditProductPage;
