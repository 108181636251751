import { Dialog, DialogTitle, IconButton } from '@material-ui/core';
import React, { useContext } from 'react';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import './style.scss';
import Button from '../../../../components/Button/button';
import { Button as MaterialButton } from '@material-ui/core';
import { UserSelectionContext } from '../../../../contexts/UserSelectionContext';
import { EditProductContext } from '../../../../contexts/EditProductContext';


const AddedToCartDialog = () => {
    const { 
        isSuccessDialogOpen,
        setIsSuccessDialogOpen, 
        setSelectedSection, 
        sections,
        switchFsId
    } = useContext(UserSelectionContext);
    const { changePage } = useContext(EditProductContext);

    const onClickCustomize = () => {
        setIsSuccessDialogOpen(false);
        changePage(0);
        setSelectedSection(sections[0]);
        switchFsId()
    }

    const goToCartPage = () => {
        window.location.href = 'https://monzaya.fr/cart';
    }

    return (
        <Dialog aria-labelledby="simple-dialog-title" open={isSuccessDialogOpen} disableBackdropClick>
            <div className="ATCD">
                <div className="ATCD-center-child">
                    <SuccessIcon htmlColor="green" style={{ fontSize: 144 }} />
                </div>
                <div className="ATCD-success-text">Votre panier a été mis à jour</div>

                <div className="ATCD-center-child" style={{ margin: 16 }}>
                    <MaterialButton color="primary" onClick={onClickCustomize}>Personnaliser à nouveau</MaterialButton>
                </div>

                <div className="ATCD-center-child">
                    <div className="ATCD-add-to-cart-button" onClick={goToCartPage}>Voir le panier</div>
                </div>
            </div>
        </Dialog>
    );
}

export default AddedToCartDialog;
