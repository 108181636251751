import React from 'react';
import './SideSectionHeader.scss';

const SideSectionHeader = ({ primaryText, secondaryText, isMobile = false }) => {
    return (
        <div className="SideSectionHeader CPP-left-shadow" style={{ backgroundColor: isMobile ? 'rgba(255, 255, 255, .5)' : 'white' }}>
            <div className="SideSectionHeader-secondary-text">{secondaryText}</div>
            <div className="SideSectionHeader-primary-text">{primaryText}</div>
        </div>
    )
}

export default SideSectionHeader;
