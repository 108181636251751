import React, { useContext, useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import CssBaseline from "@material-ui/core/CssBaseline"
import Toolbar from "@material-ui/core/Toolbar"
import { MonZayaLogo } from "../assets"
import CustomAppBar from "../components/app-bar"
import CustomAccordion from "../components/accordion"
import { MenuContext } from './../contexts/MenuContext'

import _ from 'lodash'
import { useUser } from "../firebase/firebase"
import { CircularProgress } from "@material-ui/core"
import { useHistory } from "react-router-dom"

const drawerWidth = 303

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
    },
    appBar: {
        backgroundColor: "white",
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        marginTop: "25px",
        overflow: "auto",
    },
    content: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        color: "black",
        paddingLeft: "15px",
        fontFamily: "Source Sans Pro",
        fontSize: "16px",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "24px",
    },
    divider: {
        height: "45px",
        alignSelf: "center",
    },
}))

const Menu = ({ children }) => {
    const { user, loading } = useUser();
    const history = useHistory();
    const { state: accordionStates, dispatch: setAccordionStates, selected, setSelected, visible } = useContext(MenuContext)
    const classes = useStyles()

    const navigateTo = path => {
        history.push(path)
        return
    }

    // if (loading) {
    //     return <CircularProgress size={64} />;
    // }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <CustomAppBar logo={<MonZayaLogo />} text={user ? "Panneau D'Administration" : ''} />
            {user != null && visible && (
                <Drawer
                    className={classes.drawer}
                    variant="permanent"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <Toolbar />
                    <div className={classes.drawerContainer}>
                        {
                            Object.keys(accordionStates).map((key, index) => {
                                return <CustomAccordion
                                    key={index}
                                    selected={selected}
                                    setSelected={setSelected}
                                    index={index}
                                    setAccordionStates={setAccordionStates}
                                    accordionStates={accordionStates}
                                />
                            })
                        }
                    </div>
                </Drawer>
            )}
            <main className={classes.content} style={{ marginTop: 80 }}>
                {/* <Toolbar /> */}
                {children}
            </main>
        </div>
    )
}

export default Menu
