import React, { useState, useEffect, useContext } from "react"
import TopBar from "../components/Models/topBar"
import ProductTable from "../components/Models/table/ProductTable"
import { useQuery } from "@apollo/client"
import { GET_ALL_PRODUCTS } from "../apollo/queries"
import PrivateRoute from './../components/PrivateRoute';
import { useApi } from "../hooks/useApi"
import { EditProductContext } from "../contexts/EditProductContext"
import * as _ from 'lodash';
import { MenuContext } from "../contexts/MenuContext"
import Input from "../components/Input/Input"
import { ProductsContext } from "../contexts/ProductsContext"


const ProductsPage = () => {
  const [addClicked, setAddClicked] = useState<Boolean>(false)
  const [menusList, setMenuList] = useState<Array<string>>(["Tous les produits"])
  const [searchValue, setSearchValue] = useState<string>(null)
  const { loading: allProductsLoading, products: allProducts } = useContext(ProductsContext);
  const { setIsHiddenAdminText } = useContext(MenuContext);
  const [searchText, setSearchText] = useState('');
  const [productsToShow, setProductsToShow] = useState([]);

  useEffect(() => {
    if (addClicked) setMenuList([...menusList, "Ajouter un nouveau modèle"])
    else setMenuList(["Tous les produits"])
  }, [addClicked]);

  useEffect(() => {
    setProductsToShow(
      _.get(allProducts, 'products', [])
        .filter(d => d.title.toLowerCase().startsWith(searchText.toLowerCase()))
    )
  }, [searchText, allProducts])

  useEffect(() => {
    setIsHiddenAdminText(false);
  }, []);

  if (allProductsLoading) {
    return <h1>Loading...</h1>
  }

  return (
    <PrivateRoute>
      {/* <TopBar
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        menuList={menusList}
        addClicked={addClicked}
        setAddClicked={setAddClicked}
        buttonName={'Ajouter un nouveau produit'}
      ></TopBar> */}

      <Input value={searchText} setValue={setSearchText} placeholder="Chercher" />

      <ProductTable
        data={
          productsToShow &&
          productsToShow?.map(product => ({
            productName: product?.title,
            id: product?.id,
            image: product?.image?.src || null,
            category: product?.productType || null,
            status: true,
          }))
        }
      ></ProductTable>
    </PrivateRoute>
  )
}

export default ProductsPage
