import React, { useState } from 'react';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/button';
import { CircularProgress } from '@material-ui/core';
import { useFirebase } from '../../firebase/firebase';

const ChangePassword = () => {
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { auth } = useFirebase();

    const save = async () => {
        if (password.length < 8) {
            setError('Le mot de passe doit contenir au moins 8 caractères');
            return;
        }

        if (loading) {
            return;
        }
        setLoading(true);
        try {
            await auth.currentUser.updatePassword(password);
            alert('Succès. Le mot de passe est modifié');
        } catch(e) {
            setError(JSON.stringify(e));
        }
        setLoading(false);
    }

    return (
        <div style={{ maxWidth: 400 }}>
            <h1 style={{ marginLeft: 16 }}>Changer le mot de passe</h1>
            <Input title="Password" value={password} setValue={setPassword}  />

            <div style={{ color: 'red', marginLeft: 16 }}>{error}</div>

            <div style={{ marginBottom: 24 }}></div>

            {!loading 
                ? (
                    <div style={{ marginLeft: 16 }}>
                        <Button name="Sauvegarder" onclick={save} /> 
                    </div>
                )
                : <CircularProgress />}
        </div>
    );
}

export default ChangePassword;
