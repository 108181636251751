import { CircularProgress } from '@material-ui/core';
import React, { useContext, useRef, useState } from 'react';
import { UserSelectionContext } from '../../../contexts/UserSelectionContext';
import { upload } from '../../../firebase/firebase';
import './style.scss';
import CancelIcon from '@material-ui/icons/Cancel';
import { AddUserImage } from '../../../components/Customize/Customize';

const UserCustomizeImages = () => {
    const inputRef = useRef(null);
    const [uploading, setUploading] = useState(false);
    const { userImageUrls: imageUrls, addUserImage, removeUserImage } = useContext(UserSelectionContext);

    const onImageSelect = async (e) => {
        setUploading(true);
        const file = e.target.files[0];
        await addUserImage(file);
        setUploading(false);
    }

    return (
        <div className="UCI">
            <div className="UCI-upload-button" onClick={() => !uploading && inputRef.current.click()}>
                {uploading 
                    ? <CircularProgress /> 
                    : 'Cliquez ici pour télécharger une image'}
            </div>

            <div className="UCI-images">
                {imageUrls.map((url, index) => (
                    <div className="UCI-image-container">
                        <div className="UCI-image" style={{ backgroundImage: `url(${url})` }}>
                            <div onClick={() => removeUserImage(index)} style={{ display: 'inline-block' }}>
                                <CancelIcon className="UCI-remove-button" color="error" />
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <input 
                ref={inputRef} 
                onChange={onImageSelect} 
                style={{ position: 'absolute', left: -1000000, top: -1000000 }} 
                type="file" 
                accept="image/*" 
            />
        </div>
    )
}

export default UserCustomizeImages;
