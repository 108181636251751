import React, { useState, FC } from 'react'
import Button from '../../Button/button'
import './style.scss';
import { useFirestoreCollection, useFirestoreStorage } from '../../../firebase/firebase';

type InsertModelType = {
    setAddClicked: Function,
}

const InsertModel : FC<InsertModelType> = ({setAddClicked}) => {
    const {createRecord, loading: loadingRec} = useFirestoreCollection(['fonts'])
    const {upload, loading: loadingImg} = useFirestoreStorage(['fonts/'])
    const [model, setModel] = useState<{data: File, url: string, status: boolean, name:string, filename: string, fullPath: string}>({
        data: null, url: null,  status: false, name: null, fullPath: null, filename: null,
    })
    const [clicked, setClicked] = useState(false)
    const fileChange = (event) => {
        setModel({...model, data: event.target.files[0], url: URL.createObjectURL(event.target.files[0])})
    }

    const insert = async () => {
        if(model.data) {
            const resultUpload = await upload(model.data)
            if(resultUpload.state === 'success') {
                const result = await createRecord({name: model.name, fullPath: resultUpload.metadata.fullPath, status: model.status, filename: model.data.name})   
            }
        }
        setClicked(true)
    }

    return (<>
        <div className='editor'>
            <p>FontName *</p>
            <input className='font-name' value={model.name} onChange={(e) => setModel({...model, name: e.target.value})} placeholder='Name...'></input>
            <label className="fontupload-button">
                <input 
                    accept='.ttf' 
                    id='file-upload'  
                    className='fontupload-button' 
                    type='file' 
                    name={`Choisissez l'image du produit`} 
                    onChange={fileChange}
                />
                <i className="fa fa-cloud-upload"></i>
                Choisissez l'image du font
            </label>
            <div style={{marginTop: '10px'}}>
                <Button 
                    disable={loadingImg || loadingRec} 
                    name={(loadingImg || loadingRec) ? 'loading...' : 'insert'} 
                    onclick={insert}
                />
            </div>
            {!loadingImg && !loadingRec && clicked &&  <p>successfuly inserted</p>}
        </div>
    </>)
}

export default InsertModel;