import React, { useState, FC } from "react"
import Button from "../Button/button"
import "./topBar.scss"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Link from "@material-ui/core/Link"

type TopBarType = {
  addClicked: Boolean;
  setAddClicked: Function;
  searchValue: string;
  setSearchValue: Function;
  menuList: Array<any>;
  buttonName?: string;
  link?: string,
}
const TopBar: FC<TopBarType> = props => {
  const {
    addClicked,
    setAddClicked,
    menuList,
    searchValue,
    setSearchValue,
    buttonName,
    link,
  } = props

  const change = () => {
    setAddClicked(true)
  }

  return (
    <div className="model-top-bar">
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {menuList.map(elm => (
          <Link
            style={{
              fontFamily: "Source Sans Pro",
              fontWeight: 400,
              color: "black",
              fontSize: "23px",
            }}
            href={!link ? "/models" : link}
          >
            {elm}
          </Link>
        ))}
      </Breadcrumbs>

      <div className="left-side">
        <div className="default-search-box">
          <input
            placeholder="Rechercher..."
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
          ></input>
          <image></image>
        </div>
        <Button
          name={buttonName || "Ajouter un nouveau modèle"}
          onclick={change}
        ></Button>
      </div>
    </div>
  )
}

export default TopBar
