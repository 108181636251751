import { Box } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import TextIcon from '../../../components/TextIcon/TextIcon';
import TexteSrc from './../../../assets/icons/texte.svg';
import GeneralSrc from './../../../assets/icons/general.svg';
import ModelsSrc from './../../../assets/icons/models.svg';
import ImagesSrc from './../../../assets/icons/images.svg';
import { Section, UserSelectionContext } from '../../../contexts/UserSelectionContext';
import { EditProductContext } from '../../../contexts/EditProductContext';

const UserCustomizeBottomNav = ({ onCloseSidebar = () => {}, onOpenSidebar = () => {}, isOpenSidebar }) => {
    const { setSelectedSection, selectedSection } = useContext(UserSelectionContext);
    const { askHasImage } = useContext(EditProductContext);
    const hasImages = askHasImage();

    const [hasSelectedFirstTime, setHasSelectedFirstTime] = useState(false);

    const handleClick = (section: Section) => {
        setHasSelectedFirstTime(true);
        if (isOpenSidebar && section === selectedSection) {
            onCloseSidebar();
        } 
        if (!isOpenSidebar) {
            onOpenSidebar();
            setSelectedSection(section);
        }
    }

    return (
        <div>
            <div style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', borderTop: '1px solid rgba(100, 100, 100, .3)', paddingTop: 8 }}>
                <TextIcon isMobile name='Étape 1' src={ModelsSrc} onClick={() => handleClick(Section.MODEL)} id={Section.MODEL} selected={isOpenSidebar && selectedSection} />
                <TextIcon isMobile name='Étape 2' src={TexteSrc} onClick={() => handleClick(Section.TEXT)} id={Section.TEXT} selected={isOpenSidebar && selectedSection} />
                {hasImages && <TextIcon isMobile name='Étape 3' src={ImagesSrc} onClick={() => handleClick(Section.IMAGES)} id={Section.IMAGES} selected={isOpenSidebar && selectedSection} />}
                <TextIcon isMobile name={hasImages ? 'Étape 4' : 'Étape 3'} src={GeneralSrc} onClick={() => handleClick(Section.GENERAL)} id={Section.GENERAL} selected={isOpenSidebar && selectedSection} />
            </div>
        </div>
    )
}

export default UserCustomizeBottomNav;
